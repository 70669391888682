<template>
  <v-container fluid class="text-center">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text class="pa-1" :style="'height:' + window.height + 'px;'">
            <v-row no-gutters style="height:100%">
              <v-col class="shrink">
                <v-expand-x-transition>
                  <v-card
                    v-show="control.fab.sideMenu"
                    height="100%"
                    width="200"
                    outlined
                    class="ml-0 mr-1"
                    style="overflow-y:scroll;"
                  >                
                  <v-card-actions>
                    <v-col class="ma-0 pa-0">
                      <v-btn
                        @click="control.fab.plantDetail = true"
                        class="white--text text-center text-normal mb-1"
                        width="100%"
                        depressed
                        color="secondary"
                      >
                        <v-img
                          :lazy-src="map.icons.iconPlantBlack.url"
                          max-height="25"
                          max-width="24"
                          :src="map.icons.iconPlantBlack.url"                        
                        ></v-img>                    
                      <span style="width:100px;">
                        Plantas
                      </span>
                      <v-btn
                        @click="control.fab.plantDetail = true"
                        class="caption secondary--text text-left"
                        fab
                        color="white"
                        x-small
                      >
                        {{ catalogues.plantas.length }}
                      </v-btn>
                      </v-btn>
                      <v-btn                        
                        class="white--text text-center text-normal mb-1"
                        width="100%"
                        depressed
                        color="secondary"
                      >
                        <v-img
                          :lazy-src="this.map.icons.iconClientNormal.url"
                          max-height="26"
                          max-width="26"
                          :src="this.map.icons.iconClientNormal.url"                        
                        ></v-img>
                        <span style="width:100px;">
                          Servicios                                                 
                        </span>
                        <v-btn
                          class="caption secondary--text text-left"
                          fab
                          color="white"
                          x-small
                        >
                          {{ (scheduling.orders.items)?scheduling.orders.items.length:0 }}
                        </v-btn>
                      </v-btn>
                      <v-btn
                        @click="control.fab.configuration = true"
                        class="white--text text-center text-normal mb-1"
                        width="100%"
                        depressed
                        color="primary"
                      >
                        <v-icon class="white--text"> mdi-cog </v-icon> 
                        <span style="width:120px;paddingLeft:10px;">Configuración
                        </span>
                      </v-btn>
                      <v-btn
                        @click="control.fab.drawings = true"
                        class="white--text text-left text-normal mb-1"
                        width="100%"
                        depressed
                        color="primary"
                      >
                        <v-icon class="white--text"> mdi-drawing </v-icon> 
                        <span style="width:120px;paddingLeft:10px;">Dibujos
                        </span>
                      </v-btn>
                      
                    </v-col>
                  </v-card-actions>
                  <v-expand-transition>
                    <v-card
                      v-if="control.fab.plantDetail"
                      class="transition-fast-in-fast-out v-card--reveal elevation-0"                      
                    >
                      <v-card-title>
                        <v-row no-gutters align="center" justify="center">
                          <v-btn                          
                            @click="control.fab.plantDetail = false"                          
                            small
                            depressed
                            plain
                          >
                            <v-icon
                              small                            
                              left
                            >
                              mdi-arrow-down
                            </v-icon> regresar
                          </v-btn>
                        </v-row>
                      </v-card-title>
                      <v-card-text class="pb-0 pl-0 pr-0">
                        <div class="text-h6 black--text" style="width:180px;textAlign:center;">
                          PLANTAS
                        </div>
                        <div style="width:180px;">
                          <v-checkbox
                            v-model="map.selectAllPlants"
                            label="TODAS"
                            value="todas"
                            @change="selectAllPlants()"
                            hide-details
                            class="ma-0 pa-0"
                            color="secondary"
                          ></v-checkbox>
                          <v-checkbox
                            v-for="(m, index) in catalogues.plantas"
                            :key="'plantaIcono'+index"
                            v-model="map.select.plants"
                            :label="m.nombre"
                            :value="m.id"
                            @change="reviewEvents()"
                            hide-details
                            class="ma-0 pa-0"
                          ></v-checkbox>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-card
                      v-if="control.fab.serviceDetail"
                      class="transition-fast-in-fast-out v-card--reveal elevation-0"
                    >
                      <v-card-title>
                        <v-row no-gutters align="center" justify="center">
                          <v-btn                          
                            @click="control.fab.serviceDetail = false"                          
                            small
                            depressed
                            plain
                          >
                            <v-icon
                              small                            
                              left
                            >
                              mdi-arrow-down
                            </v-icon> regresar
                          </v-btn>
                        </v-row>
                      </v-card-title>
                      <v-card-text class="pb-0 pl-0 pr-0">
                        <div class="text-h6 black--text" style="width:180px;textAlign:center;">
                          SERVICIOS
                        </div>
                        <div style="width:180px;">
                          <!-- <v-checkbox
                            v-for="(m, index) in map.select.services"
                            :key="'clienteIcono'+index"                            
                            :label="m.pedido+'-'+m.carga"
                            :value="m.pedido+'-'+m.carga"                          
                            hide-details
                            class="ma-0 pa-0"
                          ></v-checkbox> -->
                          <div class="primary--text mt-5" style="width:180px;textAlign:center;">
                            {{(map.switchSpiders)?'Visibles en mapa':'Servicios totales'}}
                          </div>
                          Vol. Acum.: {{ numberWithCommas(map.select.services.reduce((a, b) => a*1 + b.volumen*1, 0)) }} m<span id="super">3</span>
                          Acumulado: {{ map.select.services.length }}
                        </div>
                      </v-card-text>                    
                    </v-card>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-card
                      v-if="control.fab.configuration"
                      class="transition-fast-in-fast-out v-card--reveal elevation-0"
                    >
                      <v-card-title>
                        <v-row no-gutters align="center" justify="center">
                          <v-btn                          
                            @click="control.fab.configuration = false"                          
                            small
                            depressed
                            plain
                          >
                            <v-icon
                              small                            
                              left
                            >
                              mdi-arrow-down
                            </v-icon> regresar
                          </v-btn>
                        </v-row>
                      </v-card-title>
                      <v-card-text class="pb-0 pl-0 pr-0">
                        <div class="text-h6 black--text" style="width:180px;textAlign:center;">
                          CONFIGURACIÓN
                        </div>
                        <div style="width:180px;">
                          <v-switch
                            v-model="map.switchSpiders"
                            :label="`Spiders`"
                            class="ma-0 pa-0 mt-6 mr-4 caption"
                            color="green"
                            @change="toggleSpiders(map.switchSpiders)"
                          ></v-switch>
                          <v-checkbox
                            v-model="map.colorSpider"
                            :label="`Color Spider`"
                            class="ma-0 pa-0 mt-n4 mr-4 caption"
                            @change="toggleColorSpiders()"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="map.filtraClientesSpider"
                            :label="`Filtra Clientes`"
                            class="ma-0 pa-0 mt-n4 mr-4 caption"                          
                          ></v-checkbox>
                          <v-switch
                            v-model="map.switchPlantas"
                            :label="`Plantas`"
                            class="ma-0 pa-0 mt-6 mr-4 caption"
                          ></v-switch>
                          <v-switch
                            v-model="map.switchClientes"
                            :label="`Clientes`"
                            class="ma-0 pa-0 mt-n4 mr-4 caption"
                          ></v-switch>
                          <v-switch
                            v-model="map.switchAgrupa"
                            :label="`Agrupación`"
                            class="ma-0 pa-0 mt-6 mr-4 caption"
                            @change="changeAgrupa"
                          ></v-switch>
                          <v-checkbox
                            v-model="map.agrupaMini"
                            :label="`Agrupa (icono)`"
                            class="ma-0 pa-0 mt-n4 mr-4 caption"
                            @change="map.switchAgrupa?map.agrupaMini?map.infoOptions.pixelOffset.height=-13:map.infoOptions.pixelOffset.height=-50:''"
                          ></v-checkbox>
                          <!-- <v-switch
                            v-model="map.switchVolumen"
                            :label="`Planta Movimiento`"
                            class="ma-0 pa-0 mt-6 mr-4 caption"
                          ></v-switch> -->
                        </div>
                        </v-card-text>
                    </v-card>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-card
                      v-if="control.fab.drawings"
                      class="transition-fast-in-fast-out v-card--reveal elevation-0"
                    >
                      <v-card-title>
                        <v-row no-gutters align="center" justify="center">
                          <v-btn                          
                            @click="control.fab.drawings = false"                          
                            small
                            depressed
                            plain
                          >
                            <v-icon
                              small
                              left
                            >
                              mdi-arrow-down
                            </v-icon> regresar
                          </v-btn>
                        </v-row>
                      </v-card-title>
                      <v-card-text class="pb-0 pl-0 pr-0">
                        <div class="text-h6 black--text" style="width:180px;textAlign:center;">
                          DIBUJOS
                        </div>
                        <div style="width:180px;">
                          <v-switch
                            v-model="map.switchDibujos"
                            :label="`Visibles en mapa`"
                            class="ma-0 pa-0 mt-6 mr-3 caption"
                            @change="toggleDibujos(map.switchDibujos)"
                          ></v-switch>
                          <v-checkbox
                            v-model="map.controlDibujos"
                            :label="`Muestra controles`"
                            class="ma-0 pa-0 mt-n4 mr-4 caption"                            
                          ></v-checkbox>
                        </div>
                        <div style="width:180px;" v-if="Object.keys(map.drawing.infoDrawing).length>0 && map.drawing.infoDrawing.tipo!='MARCADOR' && map.drawing.infoDrawing.tipo!='LINEA' ">
                          <div class="primary--text mt-5" style="width:180px;textAlign:center;">
                            Acumulado en:<br/>                            
                            <v-btn
                              :color="map.drawing.infoDrawing.color"
                              dark
                              tile
                              small
                            >
                              <span>{{ map.drawing.infoDrawing.tipo }} <br/>{{ map.drawing.infoDrawing.distancia }}<span v-if="map.drawing.infoDrawing.tipo=='POLIGONO' || map.drawing.infoDrawing.tipo=='RECTANGULO' " id="super">2</span></span>
                            </v-btn>
                          </div>                                                    
                          <span class="black--text" > Volumen: {{ numberWithCommas(map.drawing.infoDrawing.volumen) }} m<span id="super">3</span></span><br/>
                          <span class="black--text" > Servicios: {{ map.drawing.infoDrawing.servicios}}</span><br/>
                          <span class="black--text" > Plantas: {{ map.drawing.infoDrawing.plantas}}</span>
                        </div>
                        <div style="width:180px" v-else-if="Object.keys(map.drawing.infoDrawing).length>0 && map.drawing.infoDrawing.tipo=='MARCADOR'">
                          <div class="primary--text mt-5" style="width:180px;textAlign:center;">
                            Coordenadas del
                            <v-btn
                              color="red"
                              small
                              dark
                              tile
                            >
                              {{ map.drawing.infoDrawing.tipo }}
                            </v-btn>
                          </div>
                          <span class="black--text" >Latitud: {{ numberWithCommasCoords(map.drawing.infoDrawing.posicion.lat) }}</span><br/>
                          <span class="black--text" >Longitud: {{ numberWithCommasCoords(map.drawing.infoDrawing.posicion.lng) }}</span>
                        </div>
                        <div style="width:180px" v-else-if="Object.keys(map.drawing.infoDrawing).length>0 && map.drawing.infoDrawing.tipo=='LINEA'">
                          <div class="primary--text mt-5" style="width:180px;textAlign:center;">
                            Distancia<br/>
                            <v-btn
                              :color="map.drawing.infoDrawing.color" 
                              small
                              dark
                              tile
                            >
                              {{ map.drawing.infoDrawing.tipo }}
                            </v-btn>
                          </div>
                          <span class="black--text" >{{ numberWithCommas(map.drawing.infoDrawing.distancia) }} Km</span>
                        </div>

                      </v-card-text>                    
                    </v-card>
                  </v-expand-transition>
                  </v-card>
                </v-expand-x-transition>
              </v-col>
              <v-col>
                <gmap-map id="map" ref="gmap"
                :center= "map.center"
                :zoom= "map.zoom"
                style= "width: 100%; height: 100%"                
                @idle= "changeIdle"
                :options= "map.options"
                @bounds_changed= "changeBounds"
                @click = "clearSelection();map.drawing.infoDrawing={}"
                >
                  <gmap-marker                  
                    v-for="(m, index) in catalogues.plantas"
                    :key="index"
                    :position="getFormatPosition(m.ubicacion)"
                    :draggable="false"                  
                    :label= "(map.actualZoom>9 && m.gemela<1 || map.actualZoom>9 )?{
                      text: m.nombre,
                      color: `${(!map.switchVolumen)?(map.select.plants && map.select.plants.find(element => element==m.id))?'#A0240E':'#000':(map.select.plants && map.select.plants.find(element => element==m.id))?'#A0240E':'#0D47A1'}`,
                      fontSize:'8px',
                      fontWeight:'bold',
                      className: 'shadowLabel'
                    }:null"
                    :icon="
                      (map.select.plants && map.select.plants.find(element => element==m.id))?
                        (map.actualZoom>11)?map.icons.iconPlantNormalSelected:(map.switchAgrupa)?map.icons.iconPlantMiniSelected:map.icons.iconPlantSmallSelected
                      :(map.switchVolumen)?
                          (map.actualZoom>11)?map.icons.iconPlantNormal:(map.switchAgrupa)?map.icons.iconPlantMini:map.icons.iconPlantSmall:
                          (map.actualZoom>11)?map.icons.iconPlantNormalQ:(map.switchAgrupa)?map.icons.iconPlantMini:map.icons.iconPlantSmallQ"
                    @click="clickPlant(m.id)"
                    :visible="map.switchPlantas"
                    >
                  </gmap-marker>
                  <gmap-cluster
                    v-if="map.switchClientes"                
                    :minimumClusterSize="map.switchAgrupa?1:999"
                    :zoomOnClick="false"
                    :styles="map.agrupaMini?map.cluster.clusterStylesMini:map.cluster.clusterStyles"
                    @click="toggleInfoWindow($event)"
                    :maxZoom="20"
                    :gridSize="50"
                    :averageCenter="true"
                    ref="clusterer"
                    :calculator="setCalculator"
                  >
                    <template v-for="(m, index) in scheduling.orders.items">
                    <gmap-marker
                        v-if="map.filtraClientesSpider && map.switchSpiders?(map.select.plants && isSelected(m.planta)):true"
                        :key="'client'+index"
                        :position="{lat:m.latitud*1,lng:m.longitud*1}"
                        :draggable="false"
                        :icon="(map.actualZoom>13)?map.icons.iconClientNormal:map.icons.iconClientSmall"
                        :title="getFormatText(m)"
                        :label="map.switchAgrupa?''+m.volumen:''"
                        @click="toggleInfoWindowMarker(m)">
                    </gmap-marker>
                    </template>
                  </gmap-cluster>
                  <gmap-info-window :options="map.infoOptions" :position="map.infoWindowPos" :opened="map.infoWinOpen" @closeclick="map.infoWinOpen=false">
                    <v-card color="secondary" elevation="0">                  
                      <v-card-title class="ma-0 pa-0">
                        <span class="ml-5 mt-0 white--text py-3">{{ map.infoWindowTitle }}</span>
                        <v-img
                          :lazy-src="map.icons.iconClientNormal.url"
                          max-height="26"
                          max-width="26"
                          :src="map.icons.iconClientNormal.url"
                          class="ml-5 mt-0"
                        ></v-img>
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-if="map.infoContent.length>1"
                          v-model="map.search"
                          append-icon="mdi-magnify"
                          label="Búsqueda"
                          single-line
                          hide-details
                          dark
                          class="mt-0 mb-2 mr-2"                      
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                        v-if="map.switchAgrupa && map.infoContent.length>1"
                        :headers="map.headers"
                        :items="map.infoContent"
                        :items-per-page="5"
                        class="mb-1"
                        dense
                        :search="map.search"
                        multi-sort                    
                        :fixed-header="true"
                        :height="'220px'"
                        style="border:1px solid #ccc;borderRadius:0;"                    
                      >
                        <template
                          v-slot:body="{ items }">
                          <tbody>
                            <tr
                              v-for="(item,index) in items"
                              :key="'trclients'+index">
                              <td
                                v-for="(header, index) in map.headers"
                                :key="'thclients'+index"
                                class="text-left caption"
                                >
                                <v-chip v-if="header.value==='volumen'" class="caption" :color="getColor(item[header.value])" small label dark>{{ item[header.value] }}m<span id="super">3</span></v-chip>
                                <span v-else style="white-space: nowrap" class="caption black--text">{{ item[header.value] }} </span>
                              </td>
                            </tr>                        
                          </tbody>
                        </template>
                      </v-data-table>
                      <v-simple-table v-if="!map.switchAgrupa || map.infoContent.length<2"
                        class="mb-1"
                        dense
                        style="border:1px solid #ccc;borderRadius:0;">
                        <template v-slot:default>                      
                          <tbody class="caption">
                              <template v-if="map.infoContent[0]">
                                <tr><td>Pedido</td><td>{{ map.infoContent[0].pedido }}</td></tr>
                                <tr><td>Carga</td><td>{{ map.infoContent[0].carga }}</td></tr>
                                <tr><td>Cliente</td><td>{{ map.infoContent[0].cliente }}</td></tr>
                                <tr><td>Proyecto</td><td>{{ map.infoContent[0].proyecto }}</td></tr>
                                <tr><td>Planta</td><td>{{ map.infoContent[0].planta }}</td></tr>
                                <tr><td>Fecha Compromiso</td><td>{{ map.infoContent[0].fecha_compromiso }}</td></tr>
                                <tr><td>Fecha Programado</td><td>{{ map.infoContent[0].fecha_programado }}</td></tr>
                                <tr><td>Volumen</td><td>{{ map.infoContent[0].volumen }}m<span id="super">3</span></td></tr>
                              </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </gmap-info-window>
                  <gmap-polyline
                    v-for="(path , index) in map.spiders"
                    :key="index + '-spider'"
                    :options = "path.pathOptions"
                    :path.sync="path.path">
                  </gmap-polyline>
                  <div id="style-selector-control" class="map-control">
                    <v-select class="caption" 
                      v-model="map.mapSelectedStyle" 
                      @change="updateMapStyle()"
                      :items="map.mapStyleItems"
                      solo
                      dense
                      prepend-inner-icon="mdi-map"
                      light 
                      hide-details 
                      style="max-width: 180px;"
                      color="secondary"
                      >
                    </v-select>
                  </div>
                  <div id="style-drawing-control" class="map-control" v-show="map.controlDibujos && map.switchDibujos">
                    <v-btn v-for="(m,index) in map.drawing.drawingModes" 
                          :style="(map.drawing.selectedMode==m.mode)?'border:2px solid #789' : 'border:2px solid #fff'" 
                          :key="'btnDraw'+index"
                          class="ml-1 text-normal"
                          @click="map.drawing.drawingManager.setDrawingMode(m.mode);map.drawing.selectedMode=m.mode;">
                      {{m.name}}
                    </v-btn>
                    <br/>
                    <v-btn v-for="(m,index) in map.drawing.colors" 
                      :key="'colorDraw'+index" 
                      :color="m" 
                      class="mt-1 ml-1 text-normal"
                      x-small
                      :style="(map.drawing.selectedColor==m)?'border:2px solid #789' : 'border:2px solid #fff'" 
                      @click="selectColor(m);setSelectedShapeColor(m);"/>
                    <br/>
                    <v-btn small color="secondary" class="mt-1 ml-1 text-normal" @click="deleteSelectedShape">
                      Borrar seleccionado<v-icon right>
                        mdi-delete-forever
                      </v-icon>
                    </v-btn>
                    <v-btn small color="secondary" class="mt-1 ml-1 text-normal" @click="deleteAllShape">
                      Borrar todo<v-icon right>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </gmap-map>
                <!-- <span class="white--text">Plantas: {{ map.select.plants }}</span>
                <span class="white--text"> Clientes: {{ map.select.services.length }}</span> -->
              </v-col>
            </v-row>
            <v-speed-dial v-model="control.fab.orders" bottom right absolute direction="left" class="mb-11" transition="slide-y-reverse-transition">
              <template v-slot:activator>
                <v-btn v-model="control.fab.orders" color="#0B4484" class="text-normal" dark elevation="5" style="width:180px">
                  3. Pedidos
                  <v-icon right v-if="control.fab.orders" class="ml-14">
                    mdi-close
                  </v-icon>
                  <v-icon right v-else class="ml-14">
                    mdi-microsoft-excel
                  </v-icon>
                </v-btn>
              </template>
              <v-btn dark color="blue darken-1" class="text-normal" @click="cancel(); dialogs.import.orders = true;">
                Importar
                <v-icon right>mdi-upload</v-icon>
              </v-btn>
              <v-btn v-show="scheduling.orders.items.length" dark color="blue darken-1" class="text-normal" @click="dialogs.orders = true;">
                Mostrar
                <v-icon right>mdi-eye</v-icon>
              </v-btn>
              <v-btn v-show="scheduling.orders.items.length" dark color="blue darken-1" class="text-normal" @click="showChart()">
                Gráfica
                <v-icon right>mdi-chart-bar</v-icon>
              </v-btn>
            </v-speed-dial>
            <v-speed-dial v-model="control.fab.times" bottom right absolute direction="left"  style="margin-bottom:85px;" transition="slide-y-reverse-transition">
              <template v-slot:activator>
                <v-btn v-model="control.fab.times" color="#0B4484" class="text-normal" dark elevation="5" style="width:180px">
                  2. Tiempos
                  <v-icon right v-if="control.fab.times" class="ml-13">
                    mdi-close
                  </v-icon>
                  <v-icon right v-else class="ml-13">
                    mdi-microsoft-excel
                  </v-icon>
                </v-btn>
              </template>
              <v-btn dark color="blue darken-1" class="text-normal" @click="cancel(); dialogs.import.times = true;">
                Importar
                <v-icon right>mdi-upload</v-icon>
              </v-btn>
              <v-btn v-show="scheduling.times.items.length" dark color="blue darken-1" class="text-normal" @click="dialogs.times = true;">
                Mostrar
                <v-icon right>mdi-eye</v-icon>
              </v-btn>
            </v-speed-dial>
            <v-speed-dial v-model="control.fab.sizes" bottom right absolute direction="left" style="margin-bottom:125px;" transition="slide-y-reverse-transition">
              <template v-slot:activator>
                <v-btn v-model="control.fab.sizes" color="#0B4484" class="text-normal" dark elevation="5" style="width:180px">
                  1. Capacidades
                  <v-icon right v-if="control.fab.sizes" class="ml-5">
                    mdi-close
                  </v-icon>
                  <v-icon right v-else class="ml-5">
                    mdi-microsoft-excel
                  </v-icon>
                </v-btn>
              </template>
              <v-btn dark color="blue darken-1" class="text-normal" @click="cancel(); dialogs.import.sizes = true;">
                Importar
                <v-icon right>mdi-upload</v-icon>
              </v-btn>
              <v-btn v-show="scheduling.sizes.items.length" dark color="blue darken-1" class="text-normal" @click="dialogs.sizes = true;">
                Mostrar
                <v-icon right>mdi-eye</v-icon>
              </v-btn>
            </v-speed-dial>
            <v-speed-dial v-if="scheduling.orders.items.length && scheduling.times.items.length && scheduling.sizes.items.length"
            v-model="control.fab.simulation" bottom right absolute direction="left" style="margin-bottom:285px;" transition="slide-y-reverse-transition">
              <template v-slot:activator>
                <v-btn v-model="control.fab.sizes" color="success" class="text-normal" dark elevation="5" style="width:180px">
                  Simulación
                  <v-icon right v-if="control.fab.simulation" class="ml-5">
                    mdi-close
                  </v-icon>
                  <v-icon right v-else class="ml-5">
                    mdi-cogs
                  </v-icon>
                </v-btn>
              </template>
              <v-btn dark color="blue darken-1" class="text-normal" @click="downloadCSVSimulationInput()">
                {{ 'Input (.dat)' }}
                <v-icon right>mdi-download</v-icon>
              </v-btn>
              <v-btn dark color="blue darken-1" class="text-normal" @click="downloadCSVTruckSimulationInput()">
                {{ 'Input (camiones)' }}
                <v-icon right>mdi-download</v-icon>
              </v-btn>
              <v-btn dark color="warning" class="text-normal" @click="runFulfilmentSimulation()">
                {{ 'Cumplimiento' }}
                <v-icon right>mdi-clock</v-icon>
              </v-btn>
            </v-speed-dial>
            <v-btn color="white" class="mb-1 text-normal" bottom right absolute @click="control.fab.sideMenu=!control.fab.sideMenu" elevation="5" style="width:180px">
              Menú lateral<v-icon small class="ml-11">{{control.fab.sideMenu?'mdi-arrow-left-box':'mdi-arrow-right-box'}}</v-icon>
            </v-btn>
            <v-btn color="secondary" class="text-normal" dark bottom right absolute @click="dialogs.loadSchedule=!dialogs.loadSchedule" elevation="5" style="margin-bottom:165px;width:180px">
              Agenda de Carga<v-icon small class="ml-2">mdi-playlist-check</v-icon>
            </v-btn>
            <v-btn color="secondary" class="text-normal" dark bottom right absolute @click="dialogs.truckSchedule=!dialogs.truckSchedule" elevation="5" style="margin-bottom:205px;width:180px">
              Camiones/Cargas<v-icon small class="ml-2">mdi-book-clock</v-icon>
            </v-btn>
            <v-btn color="secondary" class="text-normal" dark bottom right absolute @click="dialogs.fulfillment=!dialogs.fulfillment" elevation="5" style="margin-bottom:245px;width:180px">
              Cumplimiento<v-icon small class="ml-9">mdi-percent</v-icon>
            </v-btn>
          </v-card-text>          
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogs.import.orders" persistent max-width="1000px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Importar pedidos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn v-if="!forms.import.orders.data.items.length" text small :loading="loading" @click="acceptOrders()">
            Cargar
            <v-icon right>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn v-else-if="forms.import.orders.data.items.length" text small :loading="loading" @click="saveImportRecordOrders()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form v-show="!forms.import.orders.data.items.length" ref="formImport" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-file-input v-model="forms.import.orders.id" chips show-size label="Seleccionar archivo..." :accept="catalogues.mime_types" :disabled="loading"></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-switch v-model="forms.import.orders.header" label="Ignorar la primera fila" :disabled="loading"></v-switch>
                </v-col>
              </v-row>
            </v-form>
            <v-data-table v-show="forms.import.orders.data.items.length" :headers="forms.import.orders.data.headers" :items="forms.import.orders.data.items"
            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 20, -1] }" :items-per-page="10">
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
              <template v-slot:no-data>
                No hay información disponible
              </template>
              <template v-slot:no-results>
                No se obtuvieron resultados
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.orders" persistent max-width="1000px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Pedidos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-data-table :headers="forms.import.orders.data.headers" :items="scheduling.orders.items"
            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 20, -1] }" :items-per-page="10">
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
              <template v-slot:no-data>
                No hay información disponible
              </template>
              <template v-slot:no-results>
                No se obtuvieron resultados
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.chart" fullscreen persistent>
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Pedidos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-select v-model="scheduling.orders.plants" :items="catalogues.plantas" item-value="codigo" item-text="codigo" label="Seleccionar plantas" multiple
          solo dense prepend-inner-icon="mdi-map-marker" class="mx-5" :disabled="loading" light chips hide-details style="max-width: 300px;" @change="updateOrders()">
            <template v-slot:selection="{ item, index }">
              <v-chip small v-if="index === 0">
                <span>{{ item.codigo }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ scheduling.orders.plants.length - 1 }} plantas)
              </span>
            </template>
          </v-select>
          <v-switch v-model="scheduling.orders.sizes" class="mr-5 mt-5" color="blue darken-3" dense label="Capacidades" @click="updateOrders()"></v-switch>
          <v-spacer></v-spacer>
          <v-btn text small :loading="forms.chart.loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-show="!forms.chart.loading">
          <chart-order :chart-data="forms.chart.data" :options="forms.chart.options" :height="700" class="mt-5"></chart-order>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.import.times" persistent max-width="1000px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Importar tiempos (min)</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn v-if="!forms.import.times.data.items.length" text small :loading="loading" @click="acceptTimes()">
            Cargar
            <v-icon right>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn v-else-if="forms.import.times.data.items.length" text small :loading="loading" @click="saveImportRecordTimes()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form v-show="!forms.import.times.data.items.length" ref="formImport" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-file-input v-model="forms.import.times.id" chips show-size label="Seleccionar archivo..." :accept="catalogues.mime_types" :disabled="loading"></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-switch v-model="forms.import.times.header" label="Ignorar la primera fila" :disabled="loading"></v-switch>
                </v-col>
              </v-row>
            </v-form>
            <v-data-table v-show="forms.import.times.data.items.length" :headers="forms.import.times.data.headers" :items="forms.import.times.data.items"
            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 20, -1] }" :items-per-page="10">
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
              <template v-slot:no-data>
                No hay información disponible
              </template>
              <template v-slot:no-results>
                No se obtuvieron resultados
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.times" persistent max-width="1000px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Tiempos (min)</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-data-table :headers="forms.import.times.data.headers" :items="scheduling.times.items"
            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 20, -1] }" :items-per-page="10">
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
              <template v-slot:no-data>
                No hay información disponible
              </template>
              <template v-slot:no-results>
                No se obtuvieron resultados
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.import.sizes" persistent max-width="1000px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Importar capacidades</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn v-if="!forms.import.sizes.data.items.length" text small :loading="loading" @click="acceptSizes()">
            Cargar
            <v-icon right>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn v-else-if="forms.import.sizes.data.items.length" text small :loading="loading" @click="saveImportRecordSizes()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form v-show="!forms.import.sizes.data.items.length" ref="formImport" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-file-input v-model="forms.import.sizes.id" chips show-size label="Seleccionar archivo..." :accept="catalogues.mime_types" :disabled="loading"></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-switch v-model="forms.import.sizes.header" label="Ignorar la primera fila" :disabled="loading"></v-switch>
                </v-col>
              </v-row>
            </v-form>
            <v-data-table v-show="forms.import.sizes.data.items.length" :headers="forms.import.sizes.data.headers" :items="forms.import.sizes.data.items"
            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 20, -1] }" :items-per-page="10">
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
              <template v-slot:no-data>
                No hay información disponible
              </template>
              <template v-slot:no-results>
                No se obtuvieron resultados
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.sizes" persistent max-width="1000px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Capacidades</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-data-table :headers="forms.import.sizes.data.headers" :items="scheduling.sizes.items"
            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 20, -1] }" :items-per-page="10">
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
              <template v-slot:no-data>
                No hay información disponible
              </template>
              <template v-slot:no-results>
                No se obtuvieron resultados
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.loadSchedule" persistent >
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Agenda de Carga</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid :style="'overflow-y:auto;height:'+ (window.height-150) +'px'">
            <div role="region" aria-labelledby="caption" tabindex="0">
              <table>              
                <thead>
                  <tr>
                    <th nowrap>
                      <table style="border:0;min-width:270px;max-width:270px;">
                        <tr>
                          <th style="border:0;padding-left: 0px;">                            
                            <v-select                                
                                dense
                                solo
                                v-model="scheduling.loadSchedule.plantSelected"
                                :items="catalogues.plantas"
                                :label="'Selecciona una planta'"
                                class="caption mb-1"
                                item-value="codigo"
                                hide-details=""
                                style="min-width:200px">
                                <template slot="selection" slot-scope="data">
                                  {{ data.item.codigo}} - {{data.item.nombre}}
                                </template>
                                <template slot="item" slot-scope="data">                                  
                                    <span class="caption"> {{ data.item.codigo}} - {{data.item.nombre}} </span>
                                </template>
                            </v-select>                            
                          </th>
                        </tr>
                        <tr>
                          <td style="border:0;padding-left: 0px;">
                            <v-row no-gutters>
                              <v-col class="caption">
                                <v-tooltip bottom color="rgba(0, 0, 0, 1)" >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      @click="scheduling.loadSchedule.tooltip.hidden = !scheduling.loadSchedule.tooltip.hidden"
                                      class="caption"
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                      depressed                                      
                                    >
                                      <v-icon :class="scheduling.loadSchedule.tooltip.hidden?'primary--text':'green--text'"> {{ scheduling.loadSchedule.tooltip.hidden?'mdi-toggle-switch-off':'mdi-toggle-switch'}} </v-icon> 
                                      <span>Información</span>
                                    </v-btn>
                                  </template>
                                  <span> {{ scheduling.loadSchedule.tooltip.hidden?'Muestra tooltip':'Oculta tooltip'}} </span>
                                </v-tooltip>
                              </v-col>                              
                              <v-col>                            
                                <v-btn
                                  @click="sortAndRecalculateServices(scheduling.loadSchedule.data)"
                                  class="caption"
                                  color="primary"
                                  small
                                  depressed                                  
                                >
                                  <v-icon class="white--text"> mdi-update </v-icon> 
                                  <span>Recalcula</span>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </td>
                        </tr>
                        <tr>
                          <td style="border:0;padding:0;">
                            <v-row no-gutters>
                              <v-col class="caption" cols="6">
                                <v-tooltip bottom color="rgba(0, 0, 0, 1)" >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      @click="simulation.hidden = !simulation.hidden"
                                      class="caption ml-n3"
                                      color="white"
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                      depressed                                      
                                    >
                                      <v-icon :class="simulation.hidden?'primary--text':'green--text'"> {{ simulation.hidden?'mdi-toggle-switch-off':'mdi-toggle-switch'}} </v-icon> 
                                      <span>Simulación</span>
                                    </v-btn>
                                  </template>
                                  <span> {{ simulation.hidden?'Muestra resultados simulación':'Oculta resultados simulación'}} </span>
                                </v-tooltip>
                              </v-col>                              
                            </v-row>
                          </td>
                        </tr>
                      </table>
                    </th>                  
                    <template v-if="services.load.length>0">
                      <th v-for="i in 24"
                        :key="i"
                        nowrap
                      >
                        <div style="paddingLeft:5px;width:100%;textAlign:left">{{i-1}}:00</div>
                        <div class="caption" style="position:absolute;right:42px;top:5px">Camión</div>
                        <div class="caption" style="position:absolute;right:5px;top:5px">Carga</div>
                        <div class="caption" style="width:100%">
                          <div class="caption" style="backgroundColor:#ddd;width:60%;display:inline-block;">
                            <div class="caption">Disponibles</div>
                            <div class="caption">Requeridos</div>
                            <div class="caption" style="backgroundColor:rgba(0,145,134,0.6);" v-if="simulation.requiredPerHour.trucks[scheduling.loadSchedule.plantSelected] && !simulation.hidden">
                            Simulación</div>
                          </div>
                          <div class="caption" style="backgroundColor:#eee;width:20%;display:inline-block;">
                            <!-- <div class="caption" v-html="getEstimatedTrucks(i-1)"></div>
                            <div class="caption" v-html="Object.keys(requiredTrucksPerHour[i-1]).length"></div> -->
                            <div>0</div>
                            <div>{{ scheduling.loadSchedule.requiredPerHour.trucks[scheduling.loadSchedule.plantSelected][i-1] }}</div>
                            <div v-if="simulation.requiredPerHour.trucks[scheduling.loadSchedule.plantSelected] && !simulation.hidden">{{ simulation.requiredPerHour.trucks[scheduling.loadSchedule.plantSelected][i-1] }}</div>
                          </div>                
                          <div class="caption" style="backgroundColor:#eee;width:20%;display:inline-block;">
                            <!-- <div class="caption" v-html="getCapacity(i-1)"></div>
                            <div class="caption" v-html="loadsPerHour[i-1]"></div>  -->
                            <div>{{ scheduling.sizes.sizes[scheduling.loadSchedule.plantSelected]?scheduling.sizes.sizes[scheduling.loadSchedule.plantSelected][i-1]:0 }}</div>
                            <div>{{ scheduling.loadSchedule.requiredPerHour.loads[scheduling.loadSchedule.plantSelected][i-1] }}</div>
                            <div v-if="simulation.requiredPerHour.trucks[scheduling.loadSchedule.plantSelected] && !simulation.hidden">{{ simulation.requiredPerHour.loads[scheduling.loadSchedule.plantSelected][i-1] }}</div>
                          </div>
                        </div>                    
                      </th>
                    </template>                
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in services.load"
                    :key="item.service"
                  >
                    <template>                      
                      <th class="caption"
                        nowrap                        
                        style="color:black;background:# ;borderTop:0;"
                      >
                        <v-tooltip bottom v-if="!scheduling.loadSchedule.tooltip.hidden" color="rgba(0, 0, 0, 1)">
                          <template v-slot:activator="{ on, attrs }">
                            <div class="caption" v-bind="attrs" v-on="on"  :style="'padding: 1px 4px;display:inline-block;'+serviceStyle(item.service,0)" @click="toggleSelectService(item.service,0)">
                              {{ item.service }}
                            </div>                            
                            <!-- <div title='Frecuencia' v-if="item.load==='L001'" style="display:inline-block;width:25px;backgroundColor:#888;color:#fff;textAlign:center;">{{ getFrecuency(item.order) }}</div> -->
                            <v-text-field title='Frecuencia' v-if="item.load==='L001' " hide-details class="caption" dense style="width: 25px;margin: 0;marginLeft:4px;padding: 0;display:inline-block;" :rules="rulesNumber" :value="getFrecuency(item.order)" @change="updateServiceFrecuency(item.order,$event, item.planta,index)"></v-text-field>
                            <div title='Tipo Cliente' style="display:inline-block;width:25px;backgroundColor:#0D47A1;color:#fff;textAlign:center;">{{ getClientType(item.cliente) }}</div>
                            <v-text-field title='Hora Programada' hide-details class="caption" dense style="width: 40px;margin: 0;marginLeft:4px;padding: 0;display:inline-block;" :rules="rules" :value="item.programado.split(' ')[1]" @change="redrawService(item.planta,index,$event)"></v-text-field>
                          </template>
                          <span class="caption" v-html="getServiceToolTip(item)"></span>
                        </v-tooltip>
                        <template v-else>                          
                          <div class="caption" :style="'padding: 1px 4px;display:inline-block;'+serviceStyle(item.service,0)" @click="toggleSelectService(item.service,0)">
                            {{ item.service }}
                          </div>                            
                          <!-- <div title='Frecuencia' v-if="item.load==='L001'" style="display:inline-block;width:25px;backgroundColor:#888;color:#fff;textAlign:center;">{{ getFrecuency(item.order) }}</div> -->                                                                            
                          <v-text-field title='Hora Programada' hide-details class="caption" dense style="width: 40px;margin: 0;marginLeft:4px;padding: 0;display:inline-block;" :rules="rules" :value="item.programado.split(' ')[1]" @change="redrawService(item.planta,index,$event)"></v-text-field>
                          <div title='Tipo Cliente' style="display:inline-block;width:25px;backgroundColor:#0D47A1;color:#fff;textAlign:center;">{{ getClientType(item.cliente) }}</div>
                          <v-text-field title='Frecuencia' v-if="item.load==='L001' " hide-details class="caption" dense style="width: 25px;margin: 0;marginLeft:4px;padding: 0;display:inline-block;" :rules="rulesNumber" :value="getFrecuency(item.order)" @change="updateServiceFrecuency(item.order,$event, item.planta,index)"></v-text-field>
                        </template>                    
                      </th>
                      <td class="caption" v-for="i in 24"        
                        :key="i"
                        nowrap
                        :id="'td'+scheduling.loadSchedule.dynamic"
                        :style="'border:0;'">
                        <table>
                          <tr>
                            <td v-for="k in 6" :key="i+'surrealorder'+k" class="cell pa-0 drop" @drop="onDrop($event, JSON.stringify({ cell:index+'-'+i+'-'+k, hour:(i-1), minute:(((k*1)-1)*10)}))" @dragover.prevent @dragenter.prevent :style="k==1?'borderLeft: 1px solid #777;':'borderLeft:0'">                              
                              <v-tooltip bottom color="rgba(0, 0, 0, 1)" v-if="scheduling.loadSchedule.cellTooltips[index+'-'+i+'-'+k] && !scheduling.loadSchedule.tooltip.hidden">
                                <template v-slot:activator="{ on, attrs }">                            
                                  <div v-bind="attrs" v-on="on" class="ml-0 mt-0" :ref="'cOrderHourCell'+index+'-'+i+'-'+k"  :style="'width:22px;height:22px;backgroundColor:#eee;'"> <!-- scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Color -->
                                    <!-- <div v-if="scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Point" style="display: flex;flex-direction: column;justify-content: start;width:22px;height:22px;">
                                      <div :style="'align-self:center;'">
                                        <v-row no-gutters>
                                          <div :style="'backgroundColor:'+getColorCommitment(index)+';border-radius:0%;width:22px;height:3px'"></div>
                                        </v-row>
                                      </div>
                                      <div v-if="scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info!=='&nbsp;'"
                                           :style="'width:'+scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].size+'px;'+(scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info==='TBD' && scheduling.loadSchedule.serviceSelected==''? 'color:#000;backgroundColor:rgba(149,145,134,0.6);': serviceStyle(item.service,1))"
                                           class="resize"
                                           draggable='true'
                                           @click="toggleSelectService(item.service)" @dragstart="startDrag($event, JSON.stringify({cell: index+'-'+i+'-'+k, value: scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info, index,hour:(i-1),minute:(((k*1)-1)*10)}))">
                                        <span ><span style="padding:2px 3px 2px 3px;">{{ getTime((i-1),(((k*1)-1)*10)) }}</span> camión {{ scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info }} </span>
                                      </div>
                                      <div v-if="simulation.cellInfo[index+'-'+i+'-'+k]" :style="'marginTop:12px;zIndex:2;height:10px;width:'+(simulation.cellInfo[index+'-'+i+'-'+k].size)+'px;color:#000;backgroundColor:rgba(0,145,134,0.6);'">
                                        <span ><span style="padding:2px 3px 2px 3px;">{{ getTime((i-1),(((k*1)-1)*10)) }}</span> camión {{ simulation.cellInfo[index+'-'+i+'-'+k].Info }} </span>
                                      </div>
                                    </div> -->
                                
                                    <div class="caption" style="display: flex;flex-direction: column;justify-content: center;width:22px;height:22px;">
                                      <div :style="'zIndex:3;width:'+scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].size+'px;'+(scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info==='TBD' && scheduling.loadSchedule.serviceSelected==''? 'color:#000;backgroundColor:rgba(149,145,134,0.6);': serviceStyle(item.service,1))"
                                           class="resize"
                                           draggable='true'
                                           @click="toggleSelectService(item.service)" @dragstart="startDrag($event, JSON.stringify({cell: index+'-'+i+'-'+k, value: scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info, index,hour:(i-1),minute:(((k*1)-1)*10)}))">
                                        <!-- <div><span style="padding:2px 3px 2px 3px;">{{ getTime((i-1),(((k*1)-1)*10)) }}</span> camión {{ scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info }} </div> -->
                                        <div> {{ scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info }} </div>
                                      </div>
                                      <div v-if="simulation.cellInfo[index+'-'+i+'-'+k] && !simulation.hidden" :style="'marginTop:12px;zIndex:2;height:10px;width:'+(simulation.cellInfo[index+'-'+i+'-'+k].size)+'px;color:#000;backgroundColor:rgba(0,145,134,0.6);'">
                                        <div style="marginTop:-4px;text-align:right;" >{{ simulation.cellInfo[index+'-'+i+'-'+k].Info }} </div>
                                      </div>
                                    </div>
                                  </div>                          
                                </template>
                                <span class="caption">
                                  <template v-if="scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Color!=='#eee'">
                                    <span v-html="getInfoService(index)"></span>
                                  </template>
                                  <table>
                                    <template v-if="scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Color!=='#eee'">
                                    <tr>
                                      <td :style="'width:20px;'"></td>
                                      <td class="caption" style="width:100px;color:black">
                                        Cargando en Planta
                                      </td>
                                      <td class="caption" style="color:black">
                                        {{ getStatusTimeRange(index,'PL') }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td :style="'width:20px;'"></td>
                                      <td class="caption" style="width:100px;color:black">
                                        Hacia Obra
                                      </td>
                                      <td class="caption" style="color:black">
                                        {{ getStatusTimeRange(index,'TJB') }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td :style="'width:20px;'"></td>
                                      <td class="caption" style="width:100px;color:black">
                                        En Obra
                                      </td>
                                      <td class="caption" style="color:black">
                                        {{ getStatusTimeRange(index,'OJB') }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td :style="'width:20px;'"></td>
                                      <td class="caption" style="width:100px;color:black">
                                        Hacia Planta
                                      </td>
                                      <td class="caption" style="color:black">
                                        {{ getStatusTimeRange(index,'TPL') }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td :style="'width:20px;'"></td>
                                      <td class="caption" style="width:100px;color:black">
                                        En Planta
                                      </td>
                                      <td class="caption" style="color:black">
                                        {{ getStatusTimeRange(index,'ARR') }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><div :style="'marginLeft:5px;backgroundColor:red;border-radius:50%;width:5px;height:5px'"></div></td>
                                      <td class="caption" style="width:100px;color:black;backgroundColor:#aaa;">
                                        Compromiso
                                      </td>
                                      <td class="caption" :style="'color:black;backgroundColor:'+getColorCommitment(index)">
                                        {{ scheduling.loadSchedule.data[scheduling.loadSchedule.plantSelected].services[index].compromiso.split(' ')[1] }}
                                      </td>
                                    </tr>
                                    </template>
                                    <!-- <tr v-if="scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].PointColor=='#f00'">
                                      <td><div :style="'marginLeft:5px;backgroundColor:'+scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].PointColor+';border-radius:50%;width:22px;height:3px'"></div></td>
                                      <td class="caption" style="color:black">Compromiso</td>
                                      <td class="caption" style="color:black"></td>
                                    </tr> -->
                                  </table>
                                </span>
                              </v-tooltip>
                              <div v-else-if="scheduling.loadSchedule.cellTooltips[index+'-'+i+'-'+k] && scheduling.loadSchedule.tooltip.hidden" class="ml-0 mt-0" :ref="'cOrderHourCell'+index+'-'+i+'-'+k"  :style="'width:22px;height:22px;backgroundColor:#eee'"> <!-- + scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Color -->
                                <div v-if="scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Point" style="display: flex;flex-direction: column;justify-content: start;width:22px;height:22px;">
                                  <div :style="'align-self:center;'">
                                    <v-row no-gutters>
                                      <div :style="'backgroundColor:'+getColorCommitment(index)+';border-radius:0%;width:22px;height:3px'"></div> <!-- scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].PointColor -->
                                    </v-row>
                                  </div>
                                  <div v-if="scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info!=='&nbsp;'"
                                        :style="'zIndex:3;width:'+scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].size+'px;'+(scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info==='TBD' && scheduling.loadSchedule.serviceSelected==''? 'color:#000;backgroundColor:rgba(149,145,134,0.6)': serviceStyle(item.service,1))" 
                                        class="resize"
                                        draggable='true'
                                        @click="toggleSelectService(item.service)" @dragstart="startDrag($event, JSON.stringify({cell: index+'-'+i+'-'+k, value: scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info, index,hour:(i-1),minute:(((k*1)-1)*10)}))">
                                    <!-- <span ><span style="padding:2px 3px 2px 3px;">{{ getTime((i-1),(((k*1)-1)*10)) }}</span> camión {{ scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info }} </span> -->
                                    <span >{{ scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info }} </span>
                                  </div>
                                  <div v-if="simulation.cellInfo[index+'-'+i+'-'+k] && !simulation.hidden" :style="'marginTop:12px;zIndex:2;height:10px;width:'+(simulation.cellInfo[index+'-'+i+'-'+k].size)+'px;color:#000;backgroundColor:rgba(0,145,134,0.6);'">
                                    <div style="marginTop:-4px;text-align:right;" >{{ simulation.cellInfo[index+'-'+i+'-'+k].Info }} </div>
                                  </div>
                                </div>
                            
                                <div v-else class="caption" style="display: flex;flex-direction: column;justify-content: center;width:22px;height:22px;">
                                  <!-- <div :style="'align-self:end;'">
                                    <v-row no-gutters>
                                      <div :style="'backgroundColor:transparent;border-radius:50%;width:5px;height:5px'"></div>
                                    </v-row>
                                  </div> -->
                                  <div :style="'zIndex:3;width:'+scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].size+'px;'+(scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info==='TBD' && scheduling.loadSchedule.serviceSelected==''? 'color:#000;backgroundColor:rgba(149,145,134,0.6)': serviceStyle(item.service,1))"
                                        class="resize"
                                        draggable='true'
                                        @click="toggleSelectService(item.service)" @dragstart="startDrag($event, JSON.stringify({cell: index+'-'+i+'-'+k, value: scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info, index,hour:(i-1),minute:(((k*1)-1)*10)}))">
                                    <!-- <span ><span style="padding:2px 3px 2px 3px;">{{ getTime((i-1),(((k*1)-1)*10)) }}</span> camión {{ scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info }} </span> -->
                                    <span >{{ scheduling.loadSchedule.cellInfo[index+'-'+i+'-'+k].Info }} </span>
                                  </div>
                                  <div v-if="simulation.cellInfo[index+'-'+i+'-'+k] && !simulation.hidden" :style="'marginTop:12px;zIndex:2;height:10px;width:'+(simulation.cellInfo[index+'-'+i+'-'+k].size)+'px;color:#000;backgroundColor:rgba(0,145,134,0.6);'">
                                    <div style="marginTop:-4px;text-align:right;" >{{ simulation.cellInfo[index+'-'+i+'-'+k].Info }} </div>
                                  </div>
                                </div>                           
                              </div>
                              
                              <div v-else-if="simulation.cellTooltips[index+'-'+i+'-'+k] && !simulation.hidden" class="ml-0 mt-0" :style="'width:22px;height:22px;backgroundColor:#eee'">                                                            
                                <div class="caption" style="display: flex;flex-direction: column;justify-content: center;width:22px;height:22px;">
                                  <div :style="'marginTop:12px;zIndex:2;height:10px;width:'+(simulation.cellInfo[index+'-'+i+'-'+k].size)+'px;color:#000;backgroundColor:rgba(0,145,134,0.6);'">
                                    <div style="marginTop:-4px;text-align:right;" >{{ simulation.cellInfo[index+'-'+i+'-'+k].Info }} </div>
                                  </div>                                  
                                </div>                           
                              </div>

                              <div v-else class="ml-0 mt-0 pt-1" :ref="'cOrderHourCell'+index+'-'+i+'-'+k" :style="'width:22px;height:22px;backgroundColor:#eee'">
                                <span v-html="'&nbsp;'"></span>
                              </div>                      
                            </td>
                          </tr>
                        </table>          
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>              
            </div>
            <v-alert v-if="services.load.length<1" dark  color="primary" icon="mdi-alert-circle" class="text-left mt-5">
              <span class="caption">No hay servicios para la planta seleccionada</span>
            </v-alert>
          </v-container>
        </v-card-text>
        <span v-if="services.load.length>0" class="capiton ml-9">{{services.load.length}} servicios totales, servicios máximos/hora: {{ scheduling.loadSchedule.requiredPerHour.maxLoads[scheduling.loadSchedule.plantSelected].value }}, camiones máximos/hora: {{ scheduling.loadSchedule.requiredPerHour.maxTrucks[scheduling.loadSchedule.plantSelected].value }} </span>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.fulfillment" persistent >
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Cumplimiento</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid :style="'overflow-y:auto;height:'+ (window.height-150) +'px'">      
            <div role="region" aria-labelledby="caption" tabindex="0">    
              <table>              
                <thead>
                  <tr>
                    <th nowrap>                    
                      <table style="border:0;width:100%">
                        <tr>
                          <th style="border:0;padding-left: 0px;max-width:200px;">
                            Porcentaje de Cumplimiento
                          </th>
                        </tr>
                        <tr >
                          <td style="border:0;padding-left: 0px;"></td>                  
                        </tr>
                      </table>
                    </th>
                
                    <th v-for="i in 24"
                      :key="i"
                      nowrap
                      :ref="'plahr'+i"
                      style="backgroundColor:#fff"
                    >
                      <div style="paddingLeft:0px;width:100%;textAlign:center;">{{ formatDigit(i) }}</div>
                      <div v-if="i==6 || i==10 || i==14 || i==19|| i==24" style="font-size: 0.60rem;color:#00C7FF;paddingLeft:0px;width:100%;textAlign:right">{{ roundText[i] }}</div>
                    </th>

                    <th nowrap >
                      <div style="padding:3px;width:100%;textAlign:center">% Total</div>
                    </th>                
                  </tr>
                </thead>
                <tbody>
                  <tr 
                    v-for="(planta, index) in catalogues.plantas"
                    :key="planta.codigo"
                  >
                    <template>
                      <th class="caption" 
                        nowrap
                        :ref="'pla'+planta.id"
                        style="paddingLeft:4px;color:#fff;background:#0D47A1;borderTop:0;borderBottom: 1px solid #fff;"
                      >
                        {{index+1}}.  {{ planta.codigo }} - {{ planta.nombre }}
                      </th>
                    
                      <td class="caption" v-for="i in 24"
                        :key="i"
                        nowrap
                        :id="'td'+scheduling.fulfillment.dynamic"
                        :style="i==6 || i==10 || i==14 || i==19 || i==24?'borderRight: 2px solid #00C7FF;borderTop:0;borderBottom:0;':'border: 0;' ">
                        <table>
                          <template v-for="(itemA,indexA) in getLoadsHour(planta.codigo,(i-1))">
                            <template v-for="(item,indexC) in getFulFillment(planta.codigo,(i-1))">
                              <tr :key="'cump'+indexC+indexA" :style="'background-color:'+(itemA.value > 0? item.color : '#eee')+';'">
                                <td style="border:0;padding-left: 0px;backgroundColor:transparent;">
                                  <div class="inputCap">{{ itemA.value > 0? item.value+'%':'' }}</div>
                                </td>
                                <td :style="'color:'+itemA.color+';border:0;padding-left: 0px;backgroundColor:rgba(0,0,0,0.3);'">
                                  <div class="inputCap">{{ itemA.value > 0? itemA.value:'&nbsp;' }}</div>
                                </td>                              
                              </tr>
                            </template>
                          </template>
                        </table>
                      </td>
                      <template v-for="(itemTP,indexTP) in getFulFillment(planta.codigo,'NA','totalplant')">
                        <td :key="'useportp'+indexTP" class="caption" :style="'border:0;color:#000;backgroundColor:'+itemTP.color"> {{ itemTP.value }}% </td>
                      </template>
                    </template>
                  </tr>                  
                  <tr>
                    <th class="caption"
                      nowrap            
                      :style="'color:black;background:#ccc;border:0;textAlign:center;'"
                    >
                      % Total
                    </th>
                    <td class="caption" v-for="i in 25"
                      :key="i"
                      nowrap
                      :id="'td'+scheduling.fulfillment.dynamic"
                      style="border:0;textAlign:center;"
                      :colspan="i==25?3:1">
                      <template v-if="i<25">
                        <template v-for="(itemTH,indexTH) in getFulFillment('NA',(i-1),'totalhour')">
                          <span :key="'useporh'+indexTH" :style="'display:inline-block;width:100%;color:#000;backgroundColor:'+itemTH.color"> {{ itemTH.value }}%</span>
                        </template>
                      </template>
                      <template v-else>
                        <template v-for="(itemTT,indexTT) in getFulFillment('NA','NA','total')">
                          <span :key="'useporh'+indexTT" :style="'display:inline-block;width:100%;color:#000;backgroundColor:'+itemTT.color"> {{ itemTT.value }}%</span>
                        </template>
                      </template>
                    </td>
                  </tr>
                </tbody>                
              </table>      
            </div>      
          </v-container>
        </v-card-text>        
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.truckSchedule" persistent >
      <v-card style="min-height:500px;">
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Camiones / Cargas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-select
              dense
              solo
              v-model="scheduling.truckSchedule.plantSelected"
              :items="catalogues.plantas"
              :label="'Selecciona una planta'"
              class="caption mb-1"
              item-value="codigo"
              hide-details=""
              light
              style="max-width:200px">
              <template slot="selection" slot-scope="data">
                {{ data.item.codigo}} - {{data.item.nombre}}
              </template>
              <template slot="item" slot-scope="data">
                  <span class="caption"> {{ data.item.codigo}} - {{data.item.nombre}} </span>
              </template>
          </v-select>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div v-if="scheduling.truckSchedule.plantSelected==''" class="mt-9 title">Por favor selecciona una planta de las opciones</div>
          <div v-if="scheduling.truckSchedule.plantSelected!=='' && !scheduling.loadSchedule.requiredPerHour.trucks[scheduling.truckSchedule.plantSelected]" class="mt-9 title">
            No hay datos cargados para esta planta
          </div>
          <v-container fluid :style="'overflow-y:auto;'">
            <div role="region" aria-labelledby="caption" tabindex="0">
              <table>
                <caption class="caption mt-n1" style="color:#01579B;zIndex:3">
                  <span class="title" v-if="scheduling.truckSchedule.plantSelected!=='' && scheduling.loadSchedule.requiredPerHour.trucks[scheduling.truckSchedule.plantSelected]">
                    Agenda de Camiones
                  </span>
                </caption>
                <thead>
                  <tr v-if="scheduling.truckSchedule.plantSelected!=='' && scheduling.loadSchedule.requiredPerHour.trucks[scheduling.truckSchedule.plantSelected]">
                    <th nowrap class="ma-0 pa-0" :style="'max-width:30px;backgroundImage: linear(to bottom right, black);'">
                
                    </th>
                    <template>
                      <th v-for="i in 24"
                        :key="i"
                        nowrap
                        class="ma-0 pa-0"                  
                      >
                        <div class="ma-0" :style="'textAlign:center;'">{{ formatDigit(i) }}</div>
                      </th>
                    </template>                                        
                  </tr>
                </thead>
                <tbody v-if="scheduling.truckSchedule.plantSelected!=='' && scheduling.loadSchedule.requiredPerHour.trucks[scheduling.truckSchedule.plantSelected]">
                  <tr
                    v-for="(item, index) in scheduling.loadSchedule.requiredPerHour.maxTrucks[scheduling.truckSchedule.plantSelected].value"
                    :key="'loadhour'+index">

                    <th                         
                      class="caption"
                      style="color:black;background:white;borderTop:0;max-width:30px;"
                      nowrap                        
                      >
                      <div style="'font-size: '+ selectedFont+'rem;margin:0;textAlign:center;'">
                        {{ scheduling.loadSchedule.requiredPerHour.maxTrucks[scheduling.truckSchedule.plantSelected].value - index }}
                      </div>
                    </th>

                    <td class="caption" v-for="i in 24" :key="i" nowrap :id="'td'+scheduling.truckSchedule.dynamic" style="border:0;">                       
                      <span :key="i+'surreal'" style="min-width:100%;">
                        <template>
                          <div 
                            :ref="'cTruckHourCell'+index+'-'+i" 
                            :style="'color:#fff;backgroundColor:#eee;borderBottom: 2px solid #eee'">
                            <div :style="'width:100%;height:100%;position:relative;top:0px;'"> 
                              <div                                       
                                :style="'right:0;display:inline-block;width:100%;backgroundColor:'+((getTruckScheduleCellInfo(index,i)!=='&nbsp;')?'#0D47A1':'#ccc')+';'" 
                                v-html="getTruckScheduleCellInfo(index,i)">
                              </div>
                            </div>
                          </div>
                        </template>
                      </span>
                    </td>
                  </tr>
                </tbody>                
              </table>
            </div>
          </v-container>
          <v-container fluid :style="'overflow-y:auto;'">
            <div role="region" aria-labelledby="caption" tabindex="0">
              <table>
                <caption class="caption mt-n1" style="color:#01579B;zIndex:3">
                  <span class="title" v-if="scheduling.truckSchedule.plantSelected!=='' && scheduling.loadSchedule.requiredPerHour.trucks[scheduling.truckSchedule.plantSelected]">
                    Agenda de Cargas
                  </span>
                </caption>
                <thead>
                  <tr v-if="scheduling.truckSchedule.plantSelected!=='' && scheduling.loadSchedule.requiredPerHour.loads[scheduling.truckSchedule.plantSelected]">
                    <th nowrap class="ma-0 pa-0" :style="'max-width:30px;backgroundImage: linear(to bottom right, black);'">
                
                    </th>
                    <template>
                      <th v-for="i in 24"
                        :key="i"
                        nowrap
                        class="ma-0 pa-0"                  
                      >
                        <div class="ma-0" :style="'textAlign:center;'">{{ formatDigit(i) }}</div>
                      </th>
                    </template>                                        
                  </tr>
                </thead>
                <tbody v-if="scheduling.truckSchedule.plantSelected!=='' && scheduling.loadSchedule.requiredPerHour.loads[scheduling.truckSchedule.plantSelected]">
                  <tr
                    v-for="(item, index) in scheduling.loadSchedule.requiredPerHour.maxLoads[scheduling.truckSchedule.plantSelected].value"
                    :key="'loadhourl'+index">

                    <th                         
                      class="caption"
                      style="color:black;background:white;borderTop:0;max-width:30px;"
                      nowrap                        
                      >
                      <div style="'font-size: '+ selectedFont+'rem;margin:0;textAlign:center;'">
                        {{ scheduling.loadSchedule.requiredPerHour.maxLoads[scheduling.truckSchedule.plantSelected].value - index }}
                      </div>
                    </th>

                    <td class="caption" v-for="i in 24" :key="i" nowrap :id="'tdl'+scheduling.truckSchedule.dynamic" style="border:0;">                       
                      <span :key="i+'surreal'" style="min-width:100%;">
                        <template v-for="(r,cont) in getLoadScheduleCellInfo(index,i)">
                          <div 
                            :key="'cl'+cont"
                            :style="'color:#fff;backgroundColor:#eee;borderBottom: 2px solid #eee'">
                            <div :style="'width:100%;height:100%;position:relative;top:0px;'">
                              <v-tooltip nudge-top="50" :disabled="r.service!==''?false:true" top color="rgba(0, 0, 0, 1)" >
                                <template v-slot:activator="{ on, attrs }">
                                  <div
                                    :style="'right:0;display:inline-block;width:100%;'+getLoadScheduleCellStyle(r.service)" 
                                    v-html="r['volumen']>0?r['volumen']:'&nbsp;'"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="r.service!==''?toggleSelectService(r.service):''">
                                  </div>
                                </template>
                                <span> servicio: {{ r.service }} <br/>
                                       camión: {{ r.truck }} <br/>
                                       cliente: {{ r.cliente }} <br/>
                                       proyecto: {{ r.proyecto }} <br/>
                                       volumen: {{ r.volumen }} <br/>
                                       programado: {{ r.programado }} <br/>
                                       compromiso: {{ r.compromiso }} <br/>
                                </span>
                              </v-tooltip>                              
                            </div>
                          </div>
                        </template>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay absolute :value="loading || !company" opacity="0.9">
      <div v-if="loading" class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Cargando...</p>
      </div>
      <div v-else-if="!loading && !company" class="text-center">
        Seleccionar una empresa...
      </div>
    </v-overlay>
    <v-snackbar v-model="scheduling.orders.alert" color="blue-grey" :timeout="-1" bottom right>
      <span class="font-weight-bold">Planta:</span> {{ scheduling.orders.notify.planta }}<br/>
      <span class="font-weight-bold">Pedido:</span> {{ scheduling.orders.notify.pedido }}<br/>
      <span class="font-weight-bold">Carga:</span> {{ scheduling.orders.notify.carga }}<br/>
      <span class="font-weight-bold">Fecha compromiso:</span> {{ scheduling.orders.notify.fecha_compromiso }}<br/>
      <span class="font-weight-bold">Fecha programado:</span> {{ scheduling.orders.notify.fecha_programado }}<br/>
      <span class="font-weight-bold">Volumen:</span> {{ scheduling.orders.notify.volumen }}<br/>
      <span class="font-weight-bold">Cliente:</span> {{ scheduling.orders.notify.cliente }}<br/>
      <span class="font-weight-bold">Proyecto:</span> {{ scheduling.orders.notify.proyecto }}
    </v-snackbar>
    <v-snackbar v-model="success" color="success" class="mb-10 ml-3" :timeout="2500" bottom center>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" class="mb-10 ml-3" :timeout="2500" bottom center>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import chartOrder from '../components/Charts/Bar.js'
import { EventBus } from "../utils/eventBus.js";
import services from '@/utils/services'
import moment from 'moment-timezone'
import downloadjs from 'downloadjs'
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse'
import _ from 'lodash'

export default {
  name: 'simulationTest',
  components: {
    chartOrder,
    GmapCluster
  },
  data: () => ({
    loading: false, 
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    headers: [],
    catalogues: {
      mime_types: [ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ],
      plantas: []
    },
    window: { width: 0, height: 0 },
    control: {
      fab: {
        orders: false,
        times: false,
        sizes: false,
        sideMenu: false,
        plantDetail: false,
        serviceDetail: false,
        configuration: false,
        drawings: false
      }
    },
    map: {
      center: { lat: 19.43261, lng: -99.13321 },
      zoom: 11,
      options: {
        streetViewControlOptions: {
          position: 1
        },
        zoomControlOptions: {
          position: 8
        }
      },
      icons: {
        iconPlantSmall: '',
        iconPlantNormal: '',
        iconPlantSmallSelected: '',
        iconPlantNormalSelected: '',
        iconPlantSmallQ: '',
        iconPlantNormalQ: '',
        iconClientNormal: '',
        iconClientSmall: '',
        iconPlant: '',
        iconPlantBlack: '',
        iconPlantMini: '',
        iconPlantMiniSelected: '',
      },
      mapSelectedStyle: 'hiding',
      mapStyleItems: [
        { text:'Simple', value:'hiding' },
        { text:'Blanco & Negro', value:'silver' },
        { text:'Todo activado', value:'default' },
      ],
      mapStyles: {
        default: [],
        silver: [
          {
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }],
          },
          {
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            elementType: "labels.text.fill",
            stylers: [{ color: "#616161" }],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#f5f5f5" }],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [{ color: "#bdbdbd" }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#eeeeee" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#757575" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#e5e5e5" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [{ color: "#757575" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#dadada" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#616161" }],
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [{ color: "#e5e5e5" }],
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [{ color: "#eeeeee" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#c9c9c9" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
        ],
        hiding: [
          {
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "transparent" }],
          },        
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
        ],
      },
      cluster:{
        clusterStyles: [
          {
            textColor: "#fff",
            url: require("@/assets/cluster/gho1.png"),
            height: 51,
            width: 49,
            textSize: 12,
            fontWeight: 'normal',
            anchorIcon: [51,25]
          },
          {
            textColor: "#eee",
            url: require("@/assets/cluster/gho2.png"),
            height: 53,
            width: 51,
            textSize: 11,
            fontWeight: 'normal',
            anchorIcon: [53,25]
          },
          {
            textColor: "#eee",
            url: require("@/assets/cluster/gho3.png"),
            height: 55,
            width: 53,
            textSize: 11,
            fontWeight: 'normal',
            anchorIcon: [55,26]
          },
          {
            textColor: "#eee",
            url: require("@/assets/cluster/gho4.png"),
            height: 57,
            width: 55,
            textSize: 11,
            fontWeight: 'normal',
            anchorIcon: [57,27]
          },
          {
            textColor: "#eee",
            url: require("@/assets/cluster/gho5.png"),
            height: 57,
            width: 55,
            textSize: 11,
            fontWeight: 'normal',
            anchorIcon: [57,27]
          }
        ],
        clusterStylesMini: [
          {
            textColor: "#fff",
            url: require("@/assets/cluster/c1mini.png"),
            height: 12,
            width: 12,
            textSize: 12,
            fontWeight: 'normal',
            anchorIcon: [12,6]
          },
          {
            textColor: "#eee",
            url: require("@/assets/cluster/c2mini.png"),
            height: 12,
            width: 12,
            textSize: 11,
            fontWeight: 'normal',
            anchorIcon: [12,6]
          },
          {
            textColor: "#eee",
            url: require("@/assets/cluster/c3mini.png"),
            height: 12,
            width: 12,
            textSize: 11,
            fontWeight: 'normal',
            anchorIcon: [12,6]
          },
          {
            textColor: "#eee",
            url: require("@/assets/cluster/c4mini.png"),
            height: 12,
            width: 12,
            textSize: 11,
            fontWeight: 'normal',
            anchorIcon: [12,6]
          },
          {
            textColor: "#eee",
            url: require("@/assets/cluster/c5mini.png"),
            height: 12,
            width: 12,
            textSize: 11,
            fontWeight: 'normal',
            anchorIcon: [12,6]
          }
        ],
      },
      selectAllPlants: true,
      select:{
        plants: [],
        services: []
      },
      switchVolumen: false,
      switchAgrupa: true,
      switchPlantas: true,
      switchClientes: true,
      switchSpiders: false,
      switchDibujos: false,
      actualZoom: 11,
      infoWindowTitle: '',
      currentMidx: '',
      infoContent: [],
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -50
        }
      },
      search: '',
      headers: [
        { text: 'Planta',value: 'planta',width: "120px" },
        { text: 'Pedido', value: 'pedido',width: "120px" },
        { text: 'Carga', value: 'carga',width: "110px" },
        { text: 'Cliente', value: 'cliente',width: "120px" },
        { text: 'Proyecto', value: 'proyecto',width: "120px" },
        { text: 'Fecha Compromiso', value: 'fecha_compromiso', width: "200px" },
        { text: 'Fecha Programado', value: 'fecha_programado', width: "200px" },
        { text: 'Volumen', value: 'volumen', width: "120px" },
      ],
      spiders: [],
      filtraClientesSpider: false,
      loadingSpiders: false,
      colorSpider: true,
      drawing: {
        drawingManager: null,
        drawingModes: [],
        all_overlays: [],
        selectedShape: '',
        colors: ['#008C4F', '#C67645', '#007CB8', '#F5DF4D', '#7E212B','#F7BFBE','#5F3F32','#E9A901','#9ABCD8','#D1342D','#D3C9BF','#949597','#6A6A44','#30343F','#000','#fff'],
        selectedColor: '',        
        selectedMode: null,
        infoDrawing: {}
      },
      controlDibujos: true
    },
    forms: {
      import: {
        orders: {
          id: null,
          header: true,
          data: {
            headers: [
              { text: 'Planta', align: 'left', sortable: false, type: 'texto', value: 'planta' },
              { text: 'No. Pedido', align: 'left', sortable: false, type: 'entero', value: 'pedido' },
              { text: 'Carga', align: 'left', sortable: false, type: 'texto', value: 'carga' },
              { text: 'Fecha compromiso', align: 'left', sortable: false, type: 'fecha', value: 'fecha_compromiso' },
              { text: 'Fecha programado', align: 'left', sortable: false, type: 'fecha', value: 'fecha_programado' },
              { text: 'Volúmen', align: 'left', sortable: false, type: 'decimal', value: 'volumen' },
              { text: 'Cliente', align: 'left', sortable: false, type: 'texto', value: 'cliente' },
              { text: 'Proyecto', align: 'left', sortable: false, type: 'texto', value: 'proyecto' },
              { text: 'Latitud', align: 'left', sortable: false, type: 'decimal', value: 'latitud' },
              { text: 'Longitud', align: 'left', sortable: false, type: 'decimal', value: 'longitud' },
            ],
            items: []
          }
        },
        times: {
          id: null,
          header: false,
          data: {
            headers: [
              { text: 'No. Pedido', align: 'left', sortable: false, type: 'entero', value: 'pedido' },
            ],
            items: []
          }
        },
        sizes: {
          id: null,
          header: false,
          data: {
            headers: [
              { text: 'Planta', align: 'left', sortable: false, type: 'texto', value: 'planta' },
              { text: '00:00', align: 'left', sortable: false, type: 'texto', value: '0' },
              { text: '01:00', align: 'left', sortable: false, type: 'texto', value: '1' },
              { text: '02:00', align: 'left', sortable: false, type: 'texto', value: '2' },
              { text: '03:00', align: 'left', sortable: false, type: 'texto', value: '3' },
              { text: '04:00', align: 'left', sortable: false, type: 'texto', value: '4' },
              { text: '05:00', align: 'left', sortable: false, type: 'texto', value: '5' },
              { text: '06:00', align: 'left', sortable: false, type: 'texto', value: '6' },
              { text: '07:00', align: 'left', sortable: false, type: 'texto', value: '7' },
              { text: '08:00', align: 'left', sortable: false, type: 'texto', value: '8' },
              { text: '09:00', align: 'left', sortable: false, type: 'texto', value: '9' },
              { text: '10:00', align: 'left', sortable: false, type: 'texto', value: '10' },
              { text: '11:00', align: 'left', sortable: false, type: 'texto', value: '11' },
              { text: '12:00', align: 'left', sortable: false, type: 'texto', value: '12' },
              { text: '13:00', align: 'left', sortable: false, type: 'texto', value: '13' },
              { text: '14:00', align: 'left', sortable: false, type: 'texto', value: '14' },
              { text: '15:00', align: 'left', sortable: false, type: 'texto', value: '15' },
              { text: '16:00', align: 'left', sortable: false, type: 'texto', value: '16' },
              { text: '17:00', align: 'left', sortable: false, type: 'texto', value: '17' },
              { text: '18:00', align: 'left', sortable: false, type: 'texto', value: '18' },
              { text: '19:00', align: 'left', sortable: false, type: 'texto', value: '19' },
              { text: '20:00', align: 'left', sortable: false, type: 'texto', value: '20' },
              { text: '21:00', align: 'left', sortable: false, type: 'texto', value: '21' },
              { text: '22:00', align: 'left', sortable: false, type: 'texto', value: '22' },
              { text: '23:00', align: 'left', sortable: false, type: 'texto', value: '23' },
            ],
            items: []
          }
        }
      },
      chart: {
        data: null,
        options: {
          responsive: true,
          animation: {
            duration: 0
          },
          hover: {
            animationDuration: 0
          },
          responsiveAnimationDuration: 0,
          tooltips: {
            enabled: false
          },
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              stacked: true,
              barPercentage: 1,
              categoryPercentage: 0.9
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                callback: function(value, index, ticks) {
                  return (value / 10)
                }
              }
            }]
          },
          elements: {
            line: {
              tension: 0
            }
          }
        },
        loading: false
      },
    },
    scheduling: {
      orders: {
        items: [],
        orders: {},
        hours: Array(24),
        alert: false,
        notify: {},
        plants: [],
        sizes: false,
        clients: {}
      },
      times: {
        items: [],
        times: {}
      },
      sizes: {
        items: [],
        sizes: {}
      },
      loadSchedule: {
        plantSelected: '',
        serviceSelected:'',
        indexServicesOrder: [],
        tooltip: {
          hidden: false
        },
        dynamic: 0,
        cellTooltips: {},
        cellInfo: {},
        data: {},
        changeLog: {
          start: {},
          frecuency: {}
        },
        requiredPerHour: {
          trucks: {}, //P001:[0,0,0..] 24 espacios
          loads: {}, //P001:[0,0,0..] 24 espacios
          maxLoads: {}, //P001:{value,hour}
          maxTrucks: {}, //P001:{value,hour}
          trucksArrHour: {}, //P001:[[1,4,6],[1,4],...,[]] arreglo de horas y en cada hora arreglo de camiones
          loadsArrHour: {} //P001:[[1,4,6],[1,4],...,[]] arreglo de horas y en cada hora arreglo de cargas
        }
        },
      fulfillment: {
        dynamic: 0,
      },
      truckSchedule: {
        dynamic: 0,
        plantSelected:'',
      }
    },
    simulation: {
      orders: {}, //Pedido1:[{campos de programado},{},...],Pedido2:[],...      
      cumplimiento: {},
      dynamic: 0,      
      cellTooltips: {},
      cellInfo: {},
      data: {}, //P001:{services:{..}},P002:{},... Por planta servicios simulacion
      requiredPerHour: {
        trucks: {}, //P001:[0,0,0..] 24 espacios
        loads: {}, //P001:[0,0,0..] 24 espacios
        maxLoads: {}, //P001:{value,hour}
        maxTrucks: {}, //P001:{value,hour}
        trucksArrHour: {}, //P001:[[1,4,6],[1,4],...,[]] arreglo de horas y en cada hora arreglo de camiones
        loadsArrHour: {} //P001:[[1,4,6],[1,4],...,[]] arreglo de horas y en cada hora arreglo de cargas
      },
      hidden: false,
      heatMapColors:['#E2E6BD','#F1DE81','#F6C971','#EEAB65','#DA8459','#B9534C','#8E133B']
    },
    dialogs: {
      import: {
        orders: false,
        times: false,
        sizes: false
      },
      orders: false,
      chart: false,
      times: false,
      sizes: false,
      loadSchedule: false,
      fulfillment: false,
      truckSchedule: false,
    },
    rules: [
      value => !!value || 'Requerido.',
      value => {
        const pattern = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/
        return pattern.test(value) || 'Hora no válida.'
      },
    ],
    rulesNumber: [
      value => !!value || 'Requerido.',
      value => {
        const pattern = /^\d+$/
        return pattern.test(value) || 'Número no válido.'
      },
    ],
    roundText:{6:'R-1',10:'R1',14:'R2',19:'R3',24:'R4'},
  }),
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted() {
    this.refresh(this.$store.state.general.company)
    
    setTimeout(() => {
      this.control.fab.sideMenu=true;
    }, 800);

    this.$refs.gmap.$mapPromise.then((map) => {

      this.map.options= {
        scaleControl: true, 
        mapTypeControl: true,
        mapTypeId: 'roadmap', 
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          mapTypeIds: ['roadmap', 'terrain','satellite','hybrid'],
          position: google.maps.ControlPosition.LEFT_TOP,
        }, 
        fullscreenControl:true,
        streetViewControl:true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT
        },
        zoomControl : true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        backgroundColor:"#01579B",
        controlSize:30,
        styles: this.map.mapStyles[this.map.mapSelectedStyle]
      }
      
      const myControlMI = document.getElementById('style-selector-control');
      myControlMI.index = 1; // Esto es importante sino arroja error.
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(myControlMI);
      
      const myControlDW = document.getElementById('style-drawing-control');
      myControlDW.index = 1; // Esto es importante sino arroja error.
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(myControlDW);


      let polyOptions = {
        strokeWeight: 2,
        strokeColor: '#000',
        fillOpacity: 0.35,
        editable: true
      };

      this.map.drawing.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.POLYLINE,
            google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
        markerOptions: {
          draggable:true          
        },
        polylineOptions: {
          editable: true,
          icons: [{
            icon: {
              path: 'M 0,-1 0,1',
              strokeOpacity: 1,
              scale: 2
            },
            offset: '0',
            repeat: '10px'
          }, {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              offset: '100%',
              fillColor: 'transparent',
              fillOpacity: 1,
              strokeOpacity: 1,
              scale: 2
            }
          }],
        },
        circleOptions: polyOptions,
        polygonOptions: polyOptions,
        rectangleOptions: polyOptions,
      });

      this.map.drawing.drawingManager.setMap(map);
      this.map.drawing.selectedMode = null

      google.maps.event.addListener(this.map.drawing.drawingManager, 'overlaycomplete', (e) => {
        this.map.drawing.all_overlays.push(e);        
        //if (e.type != google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
          this.map.drawing.drawingManager.setDrawingMode(null);
          this.map.drawing.selectedMode=null

          // Add an event listener that selects the newly-drawn shape when the user
          // mouses down on it.
          let newShape = e.overlay;
          newShape.type = e.type;
          newShape.all_overlays_index = this.map.drawing.all_overlays.length-1
          google.maps.event.addListener(newShape, 'rightclick', () => {
            this.setSelection(newShape);
          });
          google.maps.event.addListener(newShape, 'click', (e) => {            
            if (e.vertex != null) {
              this.removeVertex(e,newShape.getPath())
            }
            else{
              this.getInfoDrawing(newShape)
              this.clearSelection()
            }
          });          
          this.setSelection(newShape);
        //}
      });      

      // Clear the current selection when the drawing mode is changed, or when the
      // map is clicked.
      google.maps.event.addListener(this.map.drawing.drawingManager, 'drawingmode_changed', this.clearSelection);
      
      this.map.drawing.drawingModes= [
        { mode: null, name:'Selector' },
        { mode: google.maps.drawing.OverlayType.MARKER, name: 'Marcador' },
        { mode: google.maps.drawing.OverlayType.CIRCLE, name: 'Círculo' },
        { mode: google.maps.drawing.OverlayType.POLYLINE, name: 'Línea' },
        { mode: google.maps.drawing.OverlayType.POLYGON, name: 'Polígono' },
        { mode: google.maps.drawing.OverlayType.RECTANGLE, name: 'Rectángulo' },
      ]
    })

    this.$gmapApiPromiseLazy().then(() => {

      this.map.icons.iconPlantSmall = {
        url: require('@/assets/circlemarkersel.gif'),
        size: new google.maps.Size(24,24),
        scaledSize: new google.maps.Size(24,24),
        labelOrigin: new google.maps.Point(12,28)
        /* url: require('@/assets/circlemarkersmall.png'),
        size: new google.maps.Size(24, 24),
        labelOrigin: new google.maps.Point(12, 26) */
      }
      this.map.icons.iconPlantNormal = {
        url: require('@/assets/circlemarkersel.gif'),
        size: new google.maps.Size(32,32),
        scaledSize: new google.maps.Size(32,32),
        labelOrigin: new google.maps.Point(16,36)
        /* url: require('@/assets/circlemarker.png'),
        size: new google.maps.Size(32,32),
        labelOrigin: new google.maps.Point(16, 34) */
      },
      this.map.icons.iconPlantSmallQ = {        
        url: require('@/assets/circlemarkersmall.png'),
        size: new google.maps.Size(24, 24),
        labelOrigin: new google.maps.Point(12, 28)
      }
      this.map.icons.iconPlantNormalQ = {        
        url: require('@/assets/circlemarker.png'),
        size: new google.maps.Size(32,32),
        labelOrigin: new google.maps.Point(16, 36)
      },
      this.map.icons.iconPlantNormalSelected = {
        url: require('@/assets/circlemarkerselr.png'),
        size: new google.maps.Size(32,32),
        labelOrigin: new google.maps.Point(16, 36)
      }
      this.map.icons.iconPlantSmallSelected = {
        url: require('@/assets/circlemarkersmallselr.png'),
        size: new google.maps.Size(24, 24),
        labelOrigin: new google.maps.Point(12, 28)
      }
      this.map.icons.iconPlant = {
        url: require('@/assets/plantIcon.png'),
        size: new google.maps.Size(24,25),
        labelOrigin: new google.maps.Point(12, 25)
      }
      this.map.icons.iconPlantBlack = {
        url: require('@/assets/plant.png'),
        size: new google.maps.Size(24,25),
        labelOrigin: new google.maps.Point(12, 25)
      }
      this.map.icons.iconPlantMini = {
        url: require('@/assets/plantmini.png'),
        size: new google.maps.Size(12,12),
        labelOrigin: new google.maps.Point(6, 16)
      }
      this.map.icons.iconPlantMiniSelected = {
        url: require('@/assets/plantminir.png'),
        size: new google.maps.Size(12,12),
        labelOrigin: new google.maps.Point(6, 16)
      }
      this.map.icons.iconClientNormal = {
        url: require('@/assets/clientmarker.png'),
        size: new google.maps.Size(26, 26),
        labelOrigin: new google.maps.Point(13, 30)
      }
      this.map.icons.iconClientSmall = {
        url: require('@/assets/clientmarkersmall.png'),
        size: new google.maps.Size(12, 12),
        labelOrigin: new google.maps.Point(6, 14)
      }
    })

    EventBus.$on('sendIndex', coors => {
      this.showInfoOrder(coors)
    });
    EventBus.$on('setOrder', coors => {
      this.setOrder(coors)
    });
  },
  computed: {
    google: gmapApi,
    company() {
      return this.$store.state.general.company
    },
    services() {
      let tempServ = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data))
      let tempServSimu = JSON.parse(JSON.stringify(this.simulation.data))
      let temp = []
      let tempPlant= this.scheduling.loadSchedule.plantSelected
      let tempSimu=[]
            
      if(tempServ[tempPlant]){
        temp = JSON.parse(JSON.stringify(tempServ[tempPlant].services))
        tempSimu = tempServSimu[tempPlant]?JSON.parse(JSON.stringify(tempServSimu[tempPlant].services)):[]
        
        setTimeout(() =>{
          let tempServices= JSON.parse(JSON.stringify(tempServ[tempPlant].services))
          let tempServicesSimulation= tempServSimu[tempPlant]?JSON.parse(JSON.stringify(tempServSimu[tempPlant].services)):[]
          let i,k,l=0
          let result
          this.scheduling.loadSchedule.cellTooltips={}
          this.scheduling.loadSchedule.cellInfo={}
          
          this.simulation.cellTooltips={}
          this.simulation.cellInfo={}
          
          let tempHora= 0
          let tempCelda= 1

          let indexSimu= 0

          for(i=0;i< tempServices.length;i++){ //pedido 
                                      
            result = Object.keys(tempServices[i].plantLoad.cells)
            for(l=0;l<result.length;l++){
              tempHora = (result[l].substring(0,result[l].indexOf('-'))*1+1)
              tempCelda = result[l].substring(result[l].indexOf('-')+1)
              this.scheduling.loadSchedule.cellInfo[i+'-'+ tempHora+'-'+tempCelda]={'Color': tempServices[i].plantLoad.color, 'Info': tempServices[i].truck, 'Point':false,'PointColor':'#eee','PointRL':false,'PointColorRL':'#eee', 'size':((tempServices[i].serviceSize*22)+(tempServices[i].serviceSize+((tempServices[i].serviceHourChange-1)))+(tempServices[i].serviceHourChange*2))}
              this.scheduling.loadSchedule.cellTooltips[i+'-'+tempHora+'-'+tempCelda]= {'Truck': tempServices[i].truck,'Process': 'Plant Load'}
            }

            indexSimu = tempServicesSimulation.findIndex(element => element.service === tempServices[i].service);
            
            if(indexSimu!==undefined && tempServicesSimulation[indexSimu]) {
              result = Object.keys(tempServicesSimulation[indexSimu].plantLoad.cells)
              for(l=0;l<result.length;l++){
                tempHora = (result[l].substring(0,result[l].indexOf('-'))*1+1)
                tempCelda = result[l].substring(result[l].indexOf('-')+1)
                this.simulation.cellInfo[i+'-'+ tempHora+'-'+tempCelda]={'Color': tempServicesSimulation[indexSimu].plantLoad.color, 'Info': tempServicesSimulation[indexSimu].truck, 'Point':false,'PointColor':'#eee','PointRL':false,'PointColorRL':'#eee', 'size':((tempServicesSimulation[indexSimu].serviceSize*22)+(tempServicesSimulation[indexSimu].serviceSize+((tempServicesSimulation[indexSimu].serviceHourChange-1)))+(tempServicesSimulation[indexSimu].serviceHourChange*2))}
                this.simulation.cellTooltips[i+'-'+tempHora+'-'+tempCelda]= {'Truck': tempServicesSimulation[indexSimu].truck,'Process': 'Plant Load'}
              }
            }

            /* result = Object.keys(tempServices[i].toJobSite.cells)
            for(l=0;l<result.length;l++){
              tempHora = (result[l].substring(0,result[l].indexOf('-'))*1+1)
              tempCelda = result[l].substring(result[l].indexOf('-')+1)
              this.scheduling.loadSchedule.cellInfo[i+'-'+tempHora+'-'+tempCelda]={'Color': tempServices[i].toJobSite.color, 'Info': tempServices[i].truck, 'Point':false,'PointColor':'#eee','PointRL':false,'PointColorRL':'#eee'}
              this.scheduling.loadSchedule.cellTooltips[i+'-'+tempHora+'-'+tempCelda]= {'Truck': tempServices[i].truck,'Process': 'To Jobsite'}              
            }

            result = Object.keys(tempServices[i].onJobSite.cells)
            for(l=0;l<result.length;l++){
              tempHora = (result[l].substring(0,result[l].indexOf('-'))*1+1)
              tempCelda = result[l].substring(result[l].indexOf('-')+1)
              this.scheduling.loadSchedule.cellInfo[i+'-'+tempHora+'-'+tempCelda]={'Color': tempServices[i].onJobSite.color, 'Info': tempServices[i].truck, 'Point':false,'PointColor':'#eee','PointRL':false,'PointColorRL':'#eee'}
              this.scheduling.loadSchedule.cellTooltips[i+'-'+tempHora+'-'+tempCelda]= {'Truck': tempServices[i].truck,'Process': 'On Jobsite'}              
            }

            result = Object.keys(tempServices[i].toPlant.cells)
            for(l=0;l<result.length;l++){
              tempHora = (result[l].substring(0,result[l].indexOf('-'))*1+1)
              tempCelda = result[l].substring(result[l].indexOf('-')+1)
              this.scheduling.loadSchedule.cellInfo[i+'-'+tempHora+'-'+tempCelda]={'Color': tempServices[i].toPlant.color, 'Info': tempServices[i].truck, 'Point':false,'PointColor':'#eee','PointRL':false,'PointColorRL':'#eee'}
              this.scheduling.loadSchedule.cellTooltips[i+'-'+tempHora+'-'+tempCelda]= {'Truck': tempServices[i].truck,'Process': 'To Plant'}              
            } */
            
            //POINT (red - commitment, black - plant load estimated)
            /* if(this.scheduling.loadSchedule.cellInfo[i+'-'+(tempServices[i].onJobSite.highlight.hour+1)+'-'+tempServices[i].onJobSite.highlight.cell]){
              this.scheduling.loadSchedule.cellInfo[i+'-'+(tempServices[i].onJobSite.highlight.hour+1)+'-'+tempServices[i].onJobSite.highlight.cell].Point= true;
              this.scheduling.loadSchedule.cellInfo[i+'-'+(tempServices[i].onJobSite.highlight.hour+1)+'-'+tempServices[i].onJobSite.highlight.cell].PointColor= '#f00';
            }
            else{
              this.scheduling.loadSchedule.cellInfo[i+'-'+(tempServices[i].onJobSite.highlight.hour+1)+'-'+tempServices[i].onJobSite.highlight.cell]={'Color': '#eee', 'Info': '&nbsp;', 'Point':true,'PointColor':'#f00'}
              this.scheduling.loadSchedule.cellTooltips[i+'-'+(tempServices[i].onJobSite.highlight.hour+1)+'-'+tempServices[i].onJobSite.highlight.cell]= {'Truck': tempServices[i].truck,'Process': 'Plant load'}
            } */            
          }          
        }, 500);
        this.scheduling.loadSchedule.dynamic+=1
        this.simulation.dynamic+=1

      }
      /* console.log("SIMULATION", this.simulation)
      console.log("SCHEDULING", this.scheduling) */
      return { load:temp, simulation: tempSimu }
    }
  },
  watch: {
    company: function (val) {
      this.loading = true
      setTimeout(() => {
        this.clearAll()
        this.refresh(val)
      }, 500)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    isSelected(pplanta) {      
      const tempPlantas = JSON.parse(JSON.stringify(this.catalogues.plantas));
      const plantDetail = tempPlantas.find(element => element.codigo == pplanta);
      const found = this.map.select.plants.find(element => element == plantDetail.id);
      return found
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 75;
    },
    refresh(id) {
      if (id) {
        this.loading = true
        this.axios.get(services.routes.user + '/plant' + '?id=' + this.$store.state.general.company)
          .then(response => {
            this.catalogues.plantas = response.data.data
            this.addPlants(this.forms.import.times.data.headers)
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    addPlants(arr) {
      for (let i = 0; i < this.catalogues.plantas.length; i++) {
        arr.push({
          text: this.catalogues.plantas[i].codigo,
          align: 'left',
          sortable: false,
          type: 'texto',
          value: this.catalogues.plantas[i].codigo
        })
      }
    },
    cancel() {
      if (this.dialogs.import.orders) {
        this.forms.import.orders = {
          id: null,
          header: false,
          data: {
            headers: [
              { text: 'Planta', align: 'left', sortable: false, type: 'texto', value: 'planta' },
              { text: 'No. Pedido', align: 'left', sortable: false, type: 'entero', value: 'pedido' },
              { text: 'Carga', align: 'left', sortable: false, type: 'texto', value: 'carga' },
              { text: 'Fecha compromiso', align: 'left', sortable: false, type: 'fecha', value: 'fecha_compromiso' },
              { text: 'Fecha programado', align: 'left', sortable: false, type: 'fecha', value: 'fecha_programado' },
              { text: 'Volúmen', align: 'left', sortable: false, type: 'decimal', value: 'volumen' },
              { text: 'Cliente', align: 'left', sortable: false, type: 'texto', value: 'cliente' },
              { text: 'Proyecto', align: 'left', sortable: false, type: 'texto', value: 'proyecto' },
              { text: 'Latitud', align: 'left', sortable: false, type: 'decimal', value: 'latitud' },
              { text: 'Longitud', align: 'left', sortable: false, type: 'decimal', value: 'longitud' },
            ],
            items: []
          }
        }
        this.dialogs.import.orders = false
      }
      else if (this.dialogs.orders) {
        this.dialogs.orders = false
      }
      else if (this.dialogs.chart) {
        this.scheduling.orders.alert = false
        this.forms.chart.data = {
          labels: [ 
            '00:00', '01:00', '02:00', '03:00', '04:00', '05:00',
            '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
            '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
            '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
          ],
          datasets: []
        }
        this.forms.chart.loading = false
        this.dialogs.chart = false
      }
      else if (this.dialogs.import.times) {
        this.forms.import.times = {
          id: null,
          header: false,
          data: {
            headers: [
              { text: 'No. Pedido', align: 'left', sortable: false, type: 'entero', value: 'pedido' },
            ],
            items: []
          }
        }
        this.addPlants(this.forms.import.times.data.headers)
        this.dialogs.import.times = false
      }
      else if (this.dialogs.times) {
        this.dialogs.times = false
      }
      else if (this.dialogs.import.sizes) {
        this.forms.import.sizes = {
          id: null,
          header: false,
          data: {
            headers: [
              { text: 'Planta', align: 'left', sortable: false, type: 'texto', value: 'planta' },
              { text: '00:00', align: 'left', sortable: false, type: 'entero', value: '0' },
              { text: '01:00', align: 'left', sortable: false, type: 'entero', value: '1' },
              { text: '02:00', align: 'left', sortable: false, type: 'entero', value: '2' },
              { text: '03:00', align: 'left', sortable: false, type: 'entero', value: '3' },
              { text: '04:00', align: 'left', sortable: false, type: 'entero', value: '4' },
              { text: '05:00', align: 'left', sortable: false, type: 'entero', value: '5' },
              { text: '06:00', align: 'left', sortable: false, type: 'entero', value: '6' },
              { text: '07:00', align: 'left', sortable: false, type: 'entero', value: '7' },
              { text: '08:00', align: 'left', sortable: false, type: 'entero', value: '8' },
              { text: '09:00', align: 'left', sortable: false, type: 'entero', value: '9' },
              { text: '10:00', align: 'left', sortable: false, type: 'entero', value: '10' },
              { text: '11:00', align: 'left', sortable: false, type: 'entero', value: '11' },
              { text: '12:00', align: 'left', sortable: false, type: 'entero', value: '12' },
              { text: '13:00', align: 'left', sortable: false, type: 'entero', value: '13' },
              { text: '14:00', align: 'left', sortable: false, type: 'entero', value: '14' },
              { text: '15:00', align: 'left', sortable: false, type: 'entero', value: '15' },
              { text: '16:00', align: 'left', sortable: false, type: 'entero', value: '16' },
              { text: '17:00', align: 'left', sortable: false, type: 'entero', value: '17' },
              { text: '18:00', align: 'left', sortable: false, type: 'entero', value: '18' },
              { text: '19:00', align: 'left', sortable: false, type: 'entero', value: '19' },
              { text: '20:00', align: 'left', sortable: false, type: 'entero', value: '20' },
              { text: '21:00', align: 'left', sortable: false, type: 'entero', value: '21' },
              { text: '22:00', align: 'left', sortable: false, type: 'entero', value: '22' },
              { text: '23:00', align: 'left', sortable: false, type: 'entero', value: '23' },
            ],
            items: []
          }
        }
        this.dialogs.import.sizes = false
      }
      else if (this.dialogs.sizes) {
        this.dialogs.sizes = false
      }
      else if (this.dialogs.loadSchedule) {
        this.dialogs.loadSchedule = false
      }
      else if (this.dialogs.fulfillment) {
        this.dialogs.fulfillment = false
      }
      else if (this.dialogs.truckSchedule) {
        this.dialogs.truckSchedule = false
      }
    },
    clear() {
      if (this.dialogs.import.orders) {
        this.scheduling.orders = {
          items: [],
          orders: {},
          times: [],
          hours: Array(24),
          notify: {},
          plants: [],
          clients: {}
        }
      }
      else if (this.dialogs.import.times) {
        this.scheduling.times = {
          items: [],
          times: {}
        }
      }
      else if (this.dialogs.import.sizes) {
        this.scheduling.sizes = {
          items: [],
          sizes: {}
        }
      }
    },
    clearAll() {
      this.scheduling = {
        orders: {
          items: [],
          orders: {},
          hours: Array(24),
          notify: {},
          plants: [],
          clients: {}
        },
        times: {
          items: [],
          times: {}
        },
        sizes: {
          items: [],
          sizes: {}
        },
        loadSchedule: {
          plantSelected: '',
          serviceSelected: '',
          indexServicesOrder: [],
          tooltip: {
            hidden: false
          },
          dynamic: 0,
          cellTooltips: {},
          cellInfo: {},
          data: {},
          changeLog: {
            start: {},
            frecuency: {}
          },
          requiredPerHour:{
            trucks: {}, //P001:[0,0,0..] 24 espacios
            loads: {}, //P001:[0,0,0..] 24 espacios
            maxLoads: {}, //P001:{value,hour}
            maxTrucks: {}, //P001:{value,hour}
            trucksArrHour: {}, //P001:[[1,4,6],[1,4],...,[]] arreglo de horas y en cada hora arreglo de camiones
            loadsArrHour: {} //P001:[[1,4,6],[1,4],...,[]] arreglo de horas y en cada hora arreglo de cargas
          }

        },
        fulfillment: {
          dynamic: 0,
        },
        truckSchedule: {
          dynamic: 0,
          plantSelected:''
        }
      }
    },
    isValidPlant(value) {
      for (let planta of this.catalogues.plantas) {
        if (planta.codigo.toUpperCase() === value.toUpperCase()) {
          return true
        }
      }
      return false
    },
    acceptOrders() {
      if (this.forms.import.orders.id) {
        let fr = new FileReader();
        let file = this.forms.import.orders.id
        let size = this.forms.import.orders.id.size
        if (size <= 3000000) {
          this.loading = true
          setTimeout(() => {
            fr.onload = () => {
              let data = XLSX.read(fr.result, { type: "array" })
              let sheetNames = data.SheetNames
              let worksheet = data.Sheets[sheetNames[0]]
              let csv = XLSX.utils.sheet_to_csv(worksheet).trim()
              let values = Papa.parse(csv)
              if (values.data && values.data.length) {
                values = values.data
                this.loadOrders(values)
              }
              else {
                this.error = true
                this.msgError = 'El archivo no fue leído correctamente'
              }
              this.loading = false
            }
            fr.onerror = () => {
              this.loading = false
              this.forms.import.orders.id = null
              this.error = true
              this.msgError = 'El archivo no fue leído correctamente'
            }
            fr.readAsArrayBuffer(file);
          }, 500);
        }
        else {
          this.error = true
          this.msgError = 'El archivo ha excedido el límite permitido (3 MB)'
        }
      }
      else {
        this.error = true
        this.msgError = 'Seleccionar archivo'
      }
    },
    loadOrders(data) {
      if (data.length && data[0].length == this.forms.import.orders.data.headers.length) {
        let idx = (this.forms.import.orders.header) ? 1 : 0
        for (let i = idx; i < data.length; i++) {
          let row = {}
          for (let j = 0; j < this.forms.import.orders.data.headers.length; j++) {
            row[this.forms.import.orders.data.headers[j].value] = data[i][j]
          }
          this.forms.import.orders.data.items.push(row)
        }
      }
      else {
        this.error = true
        this.msgError = 'El formato del archivo es inválido'
      }
    },
    checkImportRecordsOrders() {
      const columns = this.forms.import.orders.data.headers
      const values = this.forms.import.orders.data.items
      const regex = {
        texto: /^.{1,50}$/,
        entero: /^\d{1,10}$/,
        decimal: /^\-?\d{1,10}(\.\d{1,8})?$/
      }
      for (let i = 0; i < values.length; i++) {
        for (let j = 0; j < columns.length; j++) {
          let id = columns[j].value
          let name = columns[j].text
          let value = values[i][id]
          if (!_.has(values[i], id)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
          else if (columns[j].type === 'texto' && !regex.texto.test(value)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
          else if (columns[j].type === 'entero' && !(regex.entero.test(value) && parseInt(value) >= 0 && parseInt(value) <= 9999999999)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
          else if (columns[j].type === 'decimal' && !(regex.decimal.test(value) && Math.abs(parseFloat(value)) >= 0 && Math.abs(parseFloat(parseFloat(value).toFixed(5))) <= 9999999999.99999999)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
          else if (columns[j].value === 'planta' && columns[j].type === 'texto' && !this.isValidPlant(value)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
        }
      }
      return true
    },
    saveImportRecordOrders() {
      if (this.checkImportRecordsOrders()) {
        this.loading = true
        this.clear()
        for (let i = 0; i < 24; i++) {
          this.scheduling.orders.hours[i] = []
        }
        let tempRawData = [] //Sive para dar formato a RawData
        let tempLoadScheduleData={} //al final es this.scheduling.loadSchedule.data
        let tempPlantas=[] //plantas que tienen servicios programados

        for (let pedido of this.forms.import.orders.data.items) {
          let arr = pedido.fecha_compromiso.split('-')[0].split('/')
          let fecha_compromiso = arr[2] + '-' + arr[1] + '-' + arr[0] + ' ' + pedido.fecha_compromiso.split('-')[1]
          arr = pedido.fecha_programado.split('-')[0].split('/')
          let fecha_programado = arr[2] + '-' + arr[1] + '-' + arr[0] + ' ' + pedido.fecha_programado.split('-')[1]
          const info = {
            planta: pedido.planta,
            pedido: parseInt(pedido.pedido),
            carga: pedido.carga,
            fecha_compromiso: moment(fecha_compromiso).format('YYYY-MM-DD HH:mm'),
            fecha_programado: moment(fecha_programado).format('YYYY-MM-DD HH:mm'),
            volumen: parseFloat(pedido.volumen),
            cliente: pedido.cliente,
            proyecto: pedido.proyecto,
            latitud: parseFloat(pedido.latitud),
            longitud: parseFloat(pedido.longitud)
          }
          //Services byOrder
          if (!_.has(this.scheduling.orders.orders, info.pedido)) {
            this.scheduling.orders.orders[info.pedido] = []
          }
          this.scheduling.orders.orders[info.pedido].push(info)

          //Services byClient
          if (!_.has(this.scheduling.orders.clients, info.cliente)) {
            this.scheduling.orders.clients[info.cliente] = []
          }
          this.scheduling.orders.clients[info.cliente].push(info)

          //Services byHour
          let hour = parseInt(info.fecha_programado.split(' ')[1].split(':')[0])
          this.scheduling.orders.hours[hour].push(info)

          //Services Raw with Date format
          tempRawData.push(info)

          /*** LoadSchedule start ***/          
          if(!tempLoadScheduleData[info.planta]){
            tempLoadScheduleData[info.planta]= { 
              services: [{ service: info.pedido +' - '+ info.carga }]
            }
            tempPlantas.push(info.planta)
          }
          else{
            tempLoadScheduleData[info.planta].services.push({ service: info.pedido +' - '+ info.carga })
          }

          let lastElement = tempLoadScheduleData[info.planta].services.length-1
                                        
          tempLoadScheduleData[info.planta].services[lastElement] = JSON.parse(JSON.stringify(this.calculateStatus(info)))          
          
          /*** LoadSchedule end ***/
        }

        //Services Raw
        this.scheduling.orders.items = JSON.parse(JSON.stringify(tempRawData))
        this.map.select.services = JSON.parse(JSON.stringify(tempRawData))
        
        //LoadSchedule      
        //ordena los servicios del loadSchedule por fechaProgramado y recalcula camiones
        this.sortAndRecalculateServices(tempLoadScheduleData);
        setTimeout(() => {
          this.cancel();
          this.loading = false
          this.success = true
          this.msgSuccess = 'Pedidos guardados exitosamente'
        }, 800);
      }
    },
    prepareData(order) {
      let data = {
        labels: [ 
          '00:00', '01:00', '02:00', '03:00', '04:00', '05:00',
          '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
          '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
          '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
        ],
        datasets: []
      }
      let max = 0
      for (let hour of this.scheduling.orders.hours) {
        let length = hour.length
        for (let item of hour) {
          if (this.scheduling.orders.plants.length && this.scheduling.orders.plants.indexOf(item.planta) < 0) {
            length--
          }
        }
        if (length > max) {
          max = length
        }
      }
      for (let i = 0; i < max; i++) {
        data.datasets.push({
          data: [],
          borderColor: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
          borderWidth: 1,
          order: 2
        })
        for (let j = 0; j < 24; j++) {
          const info = this.scheduling.orders.hours[j][i]
          if (info) {
            if (this.scheduling.orders.plants.length && this.scheduling.orders.plants.indexOf(info.planta) < 0) {
              data.datasets[i].data.push(0)
            }
            else {
              data.datasets[i].data.push(10)
            }
            if (order && info.pedido == order) {
              if (!info.__select__) {
                info.__select__ = true
                data.datasets[i].backgroundColor.push('rgba(248,172,89, 1)')
                data.datasets[i].hoverBackgroundColor.push('rgba(248,172,89, 1)')
              }
              else {
                info.__select__ = false
                data.datasets[i].backgroundColor.push('rgba(155,205,151, 1)')
                data.datasets[i].hoverBackgroundColor.push('rgba(155,205,151, 1)')
              }
            }
            else {
              data.datasets[i].backgroundColor.push('rgba(155,205,151, 1)')
              data.datasets[i].hoverBackgroundColor.push('rgba(155,205,151, 1)')
            }
            data.datasets[i].borderColor.push('black')
          }
          else {
            data.datasets[i].data.push(10)
            data.datasets[i].backgroundColor.push('rgba(155,205,151, 0)')
            data.datasets[i].hoverBackgroundColor.push('rgba(155,205,151, 0)')
            data.datasets[i].borderColor.push('rgba(155,205,151, 0)')
          }
        }
      }
      let data_sizes = []
      for (let i = 0; i < 24; i++) {
        let sum = 0
        if (this.scheduling.orders.plants.length) {
          for (let planta of this.scheduling.orders.plants) {
            if (_.has(this.scheduling.sizes.sizes, planta)) {
              sum += (this.scheduling.sizes.sizes[planta][i] * 10)
            }
          }
        }
        else {
          for (let key in this.scheduling.sizes.sizes) {
            if (key !== 'Planta') {
              sum += (this.scheduling.sizes.sizes[key][i] * 10)
            }
          }
        }
        data_sizes[i] = sum
      }
      if (this.scheduling.orders.sizes) {
        data.datasets.push({
          type: 'line',
          label: 'Capacidades',
          data: data_sizes,
          borderColor: 'red',
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 1,
          backgroundColor: 'rgba(155,205,151, 0)',
          hoverBackgroundColor: 'rgba(155,205,151, 0)',
          order: 1
        })
      }
      return data
    },
    updateOrders() {
      let data = this.prepareData()
      this.forms.chart.data = data
    },
    showChart() {
      this.forms.chart.loading = true
      let data = this.prepareData()
      this.dialogs.chart = true
      setTimeout(() => {
        this.forms.chart.data = data
        this.forms.chart.loading = false
      }, 500)
    },
    showInfoOrder(coors) {
      if (this.scheduling.orders.hours[coors.x][coors.y]) {
        this.scheduling.orders.notify = JSON.parse(JSON.stringify(this.scheduling.orders.hours[coors.x][coors.y]))
        this.scheduling.orders.alert = true
      }
    },
    setOrder(coors) {
      if (this.scheduling.orders.hours[coors.x][coors.y]) {
        let data = this.prepareData(this.scheduling.orders.hours[coors.x][coors.y].pedido)
        this.forms.chart.data = data 
      }
    },
    acceptTimes() {
      if (this.forms.import.times.id) {
        let fr = new FileReader();
        let file = this.forms.import.times.id
        let size = this.forms.import.times.id.size
        if (size <= 3000000) {
          this.loading = true
          setTimeout(() => {
            fr.onload = () => {
              let data = XLSX.read(fr.result, { type: "array" })
              let sheetNames = data.SheetNames
              let worksheet = data.Sheets[sheetNames[0]]
              let csv = XLSX.utils.sheet_to_csv(worksheet).trim()
              let values = Papa.parse(csv)
              if (values.data && values.data.length) {
                values = values.data
                this.loadTimes(values)
              }
              else {
                this.error = true
                this.msgError = 'El archivo no fue leído correctamente'
              }
              this.loading = false
            }
            fr.onerror = () => {
              this.loading = false
              this.forms.import.times.id = null
              this.error = true
              this.msgError = 'El archivo no fue leído correctamente'
            }
            fr.readAsArrayBuffer(file);
          }, 500);
        }
        else {
          this.error = true
          this.msgError = 'El archivo ha excedido el límite permitido (3 MB)'
        }
      }
      else {
        this.error = true
        this.msgError = 'Seleccionar archivo'
      }
    },
    loadTimes(data) {
      if (data.length && data[0].length >= this.forms.import.times.data.headers.length) {
        let idx = (this.forms.import.times.header) ? 1 : 0
        for (let i = idx; i < data.length; i++) {
          let row = {}
          for (let j = 0; j < this.forms.import.times.data.headers.length; j++) {
            row[this.forms.import.times.data.headers[j].value] = data[i][j]
          }
          this.forms.import.times.data.items.push(row)
        }
      }
      else {
        this.error = true
        this.msgError = 'El formato del archivo es inválido'
      }
    },
    checkImportRecordsTimes() {
      const columns = this.forms.import.times.data.headers
      const values = this.forms.import.times.data.items
      const regex = {
        texto: /^.{1,50}$/,
        entero: /^\d{1,10}$/,
        decimal: /^\-?\d{1,10}(\.\d{1,8})?$/
      }
      for (let i = 0; i < values.length; i++) {
        for (let j = 0; j < columns.length; j++) {
          let id = columns[j].value
          let name = columns[j].text
          let value = values[i][id]
          if (!_.has(values[i], id)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
          else if (columns[j].type === 'texto' && !regex.texto.test(value)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
          else if (columns[j].type === 'entero' && !(regex.entero.test(value) && parseInt(value) >= 0 && parseInt(value) <= 9999999999)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
          else if (columns[j].type === 'decimal' && !(regex.decimal.test(value) && Math.abs(parseFloat(value)) >= 0 && Math.abs(parseFloat(parseFloat(value).toFixed(5))) <= 9999999999.99999999)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
        }
      }
      return true
    },
    saveImportRecordTimes() {
      if (this.checkImportRecordsTimes()) {
        this.loading = true
        this.clear()
        for (let tiempo of this.forms.import.times.data.items) {
          if (!_.has(this.scheduling.times.times, tiempo.pedido)) {
            this.scheduling.times.times[tiempo.pedido.toString()] = {}
          }
          for (let planta of this.catalogues.plantas) {
            this.scheduling.times.times[tiempo.pedido.toString()][planta.codigo] = parseFloat(parseFloat(tiempo[planta.codigo].toString()).toFixed(2))
          }
        }
        this.scheduling.times.items = JSON.parse(JSON.stringify(this.forms.import.times.data.items))

        let tempServ = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data))        
        let tempPlant = JSON.parse(JSON.stringify(this.catalogues.plantas))
        
        for(let f=0;f<tempPlant.length;f++){
          if(tempServ[tempPlant[f].codigo]){
            for(let i=0; i < tempServ[tempPlant[f].codigo].services.length;i++){
              const info = {
                planta: tempServ[tempPlant[f].codigo].services[i].planta,
                pedido: tempServ[tempPlant[f].codigo].services[i].order,
                carga: tempServ[tempPlant[f].codigo].services[i].load,
                fecha_compromiso: tempServ[tempPlant[f].codigo].services[i].compromiso,
                fecha_programado:tempServ[tempPlant[f].codigo].services[i].programado,
                volumen: tempServ[tempPlant[f].codigo].services[i].volumen,
                cliente: tempServ[tempPlant[f].codigo].services[i].cliente,
                proyecto: tempServ[tempPlant[f].codigo].services[i].proyecto,
                latitud: tempServ[tempPlant[f].codigo].services[i].latitud,
                longitud: tempServ[tempPlant[f].codigo].services[i].longitud
              }
              tempServ[tempPlant[f].codigo].services[i] = JSON.parse(JSON.stringify(this.calculateStatus(info)))
            }
          }
        }        

        setTimeout(() => {
          if(Object.keys(tempServ).length>0){
            this.sortAndRecalculateServices(tempServ);
          }
          this.cancel();
          this.loading = false
          this.success = true
          this.msgSuccess = 'Tiempos guardados exitosamente'
        }, 800);
      }
    },
    acceptSizes() {
      if (this.forms.import.sizes.id) {
        let fr = new FileReader();
        let file = this.forms.import.sizes.id
        let size = this.forms.import.sizes.id.size
        if (size <= 3000000) {
          this.loading = true
          setTimeout(() => {
            fr.onload = () => {
              let data = XLSX.read(fr.result, { type: "array" })
              let sheetNames = data.SheetNames
              let worksheet = data.Sheets[sheetNames[0]]
              let csv = XLSX.utils.sheet_to_csv(worksheet).trim()
              let values = Papa.parse(csv)
              if (values.data && values.data.length) {
                values = values.data
                this.loadSizes(values)
              }
              else {
                this.error = true
                this.msgError = 'El archivo no fue leído correctamente'
              }
              this.loading = false
            }
            fr.onerror = () => {
              this.loading = false
              this.forms.import.sizes.id = null
              this.error = true
              this.msgError = 'El archivo no fue leído correctamente'
            }
            fr.readAsArrayBuffer(file);
          }, 500);
        }
        else {
          this.error = true
          this.msgError = 'El archivo ha excedido el límite permitido (3 MB)'
        }
      }
      else {
        this.error = true
        this.msgError = 'Seleccionar archivo'
      }
    },
    loadSizes(data) {
      if (data.length && data[0].length == this.forms.import.sizes.data.headers.length) {
        let idx = (this.forms.import.sizes.header) ? 1 : 0
        for (let i = idx; i < data.length; i++) {
          let row = {}
          for (let j = 0; j < this.forms.import.sizes.data.headers.length; j++) {
            row[this.forms.import.sizes.data.headers[j].value] = data[i][j]
          }
          this.forms.import.sizes.data.items.push(row)
        }
      }
      else {
        this.error = true
        this.msgError = 'El formato del archivo es inválido'
      }
    },
    checkImportRecordsSizes() {
      const columns = this.forms.import.sizes.data.headers
      const values = this.forms.import.sizes.data.items
      const regex = {
        texto: /^.{1,50}$/,
        entero: /^\d{1,10}$/,
        decimal: /^\-?\d{1,10}(\.\d{1,8})?$/
      }
      for (let i = 0; i < values.length; i++) {
        for (let j = 0; j < columns.length; j++) {
          let id = columns[j].value
          let name = columns[j].text
          let value = values[i][id]
          if (!_.has(values[i], id)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
          else if (columns[j].type === 'texto' && !regex.texto.test(value)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
          else if (columns[j].type === 'entero' && !(regex.entero.test(value) && parseInt(value) >= 0 && parseInt(value) <= 9999999999)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
          else if (columns[j].type === 'decimal' && !(regex.decimal.test(value) && Math.abs(parseFloat(value)) >= 0 && Math.abs(parseFloat(parseFloat(value).toFixed(5))) <= 9999999999.99999999)) {
            this.error = true
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`
            return false
          }
        }
      }
      return true
    },
    saveImportRecordSizes() {
      if (this.checkImportRecordsSizes()) {
        this.loading = true
        this.clear()
        for (let planta of this.forms.import.sizes.data.items) {
          if (!_.has(this.scheduling.sizes.sizes, planta.planta)) {
            this.scheduling.sizes.sizes[planta.planta.toString()] = {}
          }
          for (let i = 0; i < 24; i++) {
            this.scheduling.sizes.sizes[planta.planta.toString()][i] = parseFloat(parseFloat(planta[i].toString()).toFixed(2))
          }
        }
        this.scheduling.sizes.items = JSON.parse(JSON.stringify(this.forms.import.sizes.data.items))
        setTimeout(() => {
          this.cancel();
          this.loading = false
          this.success = true
          this.msgSuccess = 'Capacidades guardadas exitosamente'
        }, 800);
      }
    },
    compare(a, b) {
      let comparison = 0;
      if (a.valor > b.valor) {
        comparison = 1;
      } else if (a.valor < b.valor) {
        comparison = -1;
      }
      return comparison;
    },
    getSortTimes(order) {
      let arr = null
      if (_.has(this.scheduling.times.times, order)) {
        let times = []
        for (let i = 0; i < this.catalogues.plantas.length; i++) {
          let codigo = this.catalogues.plantas[i].codigo
          times.push({
            planta: codigo,
            valor: this.scheduling.times.times[order][codigo]
          })
        }
        arr = times.sort(this.compare)
      }
      return arr
    },
    downloadCSVSimulationInput() {
      if (this.scheduling.orders.items.length && this.scheduling.times.items.length && this.scheduling.sizes.items.length) {
        let csv = ''
        // Headers
        let headers = ['Pedido']
        let abc = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j']
        for (let i = 0; i < 24; i++) {
          headers.push('h' + (i + 1))
        }
        for (let i = 0; i < this.catalogues.plantas.length; i++) {
          headers.push('p' + (i + 1))
        }
        for (let i = 0; i < this.catalogues.plantas.length; i++) {
          for (let j = 0; j < 24; j++) {
            const col = ((j + 1) < 10) ? (abc[i] + '0' + (j + 1)) : (abc[i] + (j + 1))
            headers.push(col)
          }
        }
        csv += headers.join(',') + '\n'
        // Body
        let idx = 0
        for (let key in this.scheduling.orders.orders) {
          let row = []
          row.push(key)
          for (let i = 0; i < 24; i++) {
            let count = 0
            for (let j = 0; j < this.scheduling.orders.hours[i].length; j++) {
              if (this.scheduling.orders.hours[i][j].pedido.toString() === key.toString()) {
                count++
              }
            }
            row.push(count)
          }
          let _arr = this.getSortTimes(key.toString())
          if (!_arr) {
            this.error = true
            this.msgError = `No se obtuvieron los tiempos para el pedido ${key}`
            return false
          }
          for (let i = 0; i < this.catalogues.plantas.length; i++) {
            let planta = this.catalogues.plantas[i].codigo
            let _idx = 0;
            for (let j = 0; j < _arr.length; j++) {
              if (_arr[j].planta === planta) {
                _idx = (j + 1)
              }
            }
            row.push(_idx)
          }
          // Sizes
          if (!idx) {
            for (let i = 0; i < this.catalogues.plantas.length; i++) {
              let planta = this.catalogues.plantas[i].codigo
              if (!_.has(this.scheduling.sizes.sizes, planta)) {
                this.error = true
                this.msgError = `No se obtuvieron las capacidades para la planta ${planta}`
                return false
              }
              for (let j = 0; j < 24; j++) {
                row.push(this.scheduling.sizes.sizes[planta][j])
              }
            }
          }
          csv += row.join(',') + '\n'
          idx++
        }
        downloadjs(csv, 'dat.csv' , 'text/csv')
      }
      else {
        this.error = true
        this.msgError = 'No existe suficiente información para descargar el archivo'
      }
    },
    makeCSVTruckSimulationInput() {
      let csv = ''
      if (this.scheduling.orders.items.length && this.scheduling.times.items.length && this.scheduling.sizes.items.length) {
        // Headers
        let headers = ['ped', 'carga', 'nplanta', 'minprog', 'ttran', 'm3']
        for (let i = 0; i < this.catalogues.plantas.length; i++) {
          headers.push('cam' + this.catalogues.plantas[i].codigo)
        }
        csv += headers.join(',') + '\n'
        // Body
        let rows = []
        for (let key in this.scheduling.orders.orders) {
          for (let i = 0; i < this.scheduling.orders.orders[key].length; i++) {
            let cols = []
            const carga = this.scheduling.orders.orders[key][i].carga.substring(1)
            cols.push(key)
            cols.push(carga)
            const planta = this.scheduling.orders.orders[key][i].planta
            for (let j = 0; j < this.catalogues.plantas.length; j++) {
              if (this.catalogues.plantas[j].codigo === planta) {
                cols.push(j + 1)
                break
              }
            }
            const fecha = this.scheduling.orders.orders[key][i].fecha_programado
            const hora = parseInt(fecha.split(' ')[1].split(':')[0])
            const minuto = parseInt(fecha.split(' ')[1].split(':')[1])
            const val = parseInt(hora * 60) + parseInt(minuto)
            const volumen = this.scheduling.orders.orders[key][i].volumen
            cols.push(val)
            let time = 30
            if (_.has(this.scheduling.times.times, key) && _.has(this.scheduling.times.times[key], planta)) {
              time = this.scheduling.times.times[key][planta]
            }
            cols.push(time)
            cols.push(volumen)
            rows.push(cols)
          }
        }
        let swap;
        do {
          swap = false;
          for (let i = 0; i < rows.length - 1; i++) {
            if (parseInt(rows[i][3]) > parseInt(rows[i + 1][3])) {
              var tmp = rows[i];
              rows[i] = rows[i + 1];
              rows[i + 1] = tmp;
              swap = true;
            }
          }
        } while (swap);
        let len = rows.length;
        for (var i = 0; i < 1440 - 1 - len; i++) {
          rows.push(['0', '0', '0', '0', '0', '0']);
        }
        let cols = [];
        for (let i = 0; i < this.catalogues.plantas.length; i++) {
          cols.push(5);
        }
        rows[0] = rows[0].concat(cols);
        for (let i = 0; i < rows.length; i++) {
          csv += rows[i].join(',') + '\n';
        }
      }
      return csv
    },
    downloadCSVTruckSimulationInput() {
      const csv = this.makeCSVTruckSimulationInput()
      if (csv !== '') {
        downloadjs(csv, 'camiones.csv' , 'text/csv')
      }
      else {
        this.error = true
        this.msgError = 'No existe suficiente información para descargar el archivo'
      }
    },
    runFulfilmentSimulation() {
      this.loading = true
      const csv = this.makeCSVTruckSimulationInput()
      const b64 = btoa(csv)
      let data = {
        input: b64,
        tipo: 'fulfilment'
      }
      this.axios.post(services.routes.simulation, { data })
        .then(response => {
          const result = response.data.data
          const csv = atob(result)
          this.prepareDataFulfilmentSimulationPhase1(csv)
          this.prepareDataFulfilmentSimulationPhase2(csv)
          this.success = true
          this.msgSuccess = 'Proceso ejecutado exitosamente'
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      rhours = (rhours < 10) ? '0' + rhours.toString() : rhours.toString()
      rminutes = (rminutes < 10) ? '0' + rminutes.toString() : rminutes.toString()
      return rhours + ':' + rminutes
    },
    prepareDataFulfilmentSimulationPhase1(csv) {
      let tempSimulationOrders = JSON.parse(JSON.stringify(this.scheduling.orders.orders))
      let tempSimulationOrdersByPlant= {}
      const rows = csv.trim().split('\n')
      
      if (rows.length) {
        let idx = [];
        const header = rows[0].split(',')
        for (let i = 0; i < header.length; i++) {
          if (header[i].match(/en\ ped\[p\d+\]/)) {
            idx.push(i)
          }
        }
        for (let i = 1; i < rows.length; i++) {
          const cols = rows[i].split(',')
          for (let j = 0; j < idx.length; j++) {
            if (cols[idx[j]] != '0' && cols[idx[j]] != '') {
              const pedido = cols[idx[j]]
              const carga = parseInt(cols[idx[j] + 1])
              const min = parseInt(cols[idx[j] + 2])
              const _min = this.timeConvert(min)
              if (_.has(tempSimulationOrders, pedido)) {
                for (let k = 0; k < tempSimulationOrders[pedido].length; k++) {
                  if (parseInt(tempSimulationOrders[pedido][k].carga.substring(1)) == carga) {
                    const fecha_programado = tempSimulationOrders[pedido][k].fecha_programado.split(' ')[0] + ' ' +  _min
                    tempSimulationOrders[pedido][k].fecha_programado = fecha_programado

                    /*** SimulationOrdersByPlant start ***/          
                    if(!tempSimulationOrdersByPlant[tempSimulationOrders[pedido][k].planta]){
                      tempSimulationOrdersByPlant[tempSimulationOrders[pedido][k].planta]= {
                        services: [{ service: tempSimulationOrders[pedido][k].pedido +' - '+ tempSimulationOrders[pedido][k].carga }]
                      }
                    }
                    else{
                      tempSimulationOrdersByPlant[tempSimulationOrders[pedido][k].planta].services.push({ service: tempSimulationOrders[pedido][k].pedido +' - '+ tempSimulationOrders[pedido][k].carga })
                    }
                    let lastElement = tempSimulationOrdersByPlant[tempSimulationOrders[pedido][k].planta].services.length-1

                    tempSimulationOrdersByPlant[tempSimulationOrders[pedido][k].planta].services[lastElement] = JSON.parse(JSON.stringify(this.calculateStatus(tempSimulationOrders[pedido][k])))
                    /*** SimulationOrdersByPlant end ***/
                  }
                }
              }
            }
          }
        }
        this.simulation.orders = JSON.parse(JSON.stringify(tempSimulationOrders))
        this.sortAndRecalculateServices(tempSimulationOrdersByPlant,1);
      }
    },
    prepareDataFulfilmentSimulationPhase2(csv) {
      for (let i = 0; i < this.catalogues.plantas.length; i++) {
        this.simulation.cumplimiento[this.catalogues.plantas[i].codigo] = { hora: [], dia: 0 }
        for (let j = 0; j < 24; j++) {
          this.simulation.cumplimiento[this.catalogues.plantas[i].codigo]['hora'].push(0)
        }
      }
      this.simulation.cumplimiento['__PLAZA__'] = { hora: [], dia: 0 }
      for (let j = 0; j < 24; j++) {
        this.simulation.cumplimiento['__PLAZA__']['hora'].push(0)
      }
      const rows = csv.trim().split('\n')
      if (rows.length) {
        let idx_hora = [];
        let idx_cump_hora = [];
        let idx_cump_hora_pza = null;
        let idx_cump_dia = [];
        let idx_cump_dia_pza = null;
        const header = rows[0].split(',')
        for (let i = 0; i < header.length; i++) {
          if (header[i].match(/^hora$/)) {
            for (let j = 1; j < rows.length; j++) {
              const cols = rows[j].split(',')
              if (cols[i] != '0' && cols[i] != '') {
                idx_hora.push(j)
              }
            }
            break
          }
        }
        for (let i = 0; i < header.length; i++) {
          if (header[i].match(/%\ cump\ hr\ pta\[p\d+\]/)) {
            idx_cump_hora.push(i)
          }
        }
        for (let i = 0; i < header.length; i++) {
          if (header[i].match(/%\ cump\ hr\ pza/)) {
            idx_cump_hora_pza = i
          }
        }
        for (let i = 0; i < header.length; i++) {
          if (header[i].match(/%\ cum\ pta\ dia\[p\d+\]/)) {
            idx_cump_dia.push(i)
          }
        }
        for (let i = 0; i < header.length; i++) {
          if (header[i].match(/%\ cum\ pl\ dia/)) {
            idx_cump_dia_pza = i
          }
        }
        for (let i = 1; i < rows.length; i++) {
          if (idx_hora.indexOf(i) < 0) {
            continue
          }
          const hora = idx_hora.indexOf(i)
          const cols = rows[i].split(',')
          for (let j = 0; j < idx_cump_hora.length; j++) {
            if (cols[idx_cump_hora[j]] != '0' && cols[idx_cump_hora[j]] != '') {
              const val = cols[idx_cump_hora[j]]
              this.simulation.cumplimiento[this.catalogues.plantas[j].codigo]['hora'][hora] = parseFloat(val)
            }
          }
          if (idx_cump_hora_pza) {
            const val = cols[idx_cump_hora_pza]
            this.simulation.cumplimiento['__PLAZA__']['hora'][hora] = parseFloat(val)
          }
        }
        const cols = rows[1439].split(',')
        for (let j = 0; j < idx_cump_dia.length; j++) {
          if (cols[idx_cump_dia[j]] != '0' && cols[idx_cump_dia[j]] != '') {
            const val = cols[idx_cump_dia[j]]
            this.simulation.cumplimiento[this.catalogues.plantas[j].codigo]['dia'] = parseFloat(val)
          }
        }
        if (idx_cump_dia_pza) {
          const val = cols[idx_cump_dia_pza]
          this.simulation.cumplimiento['__PLAZA__']['dia'] = parseFloat(val)
        }
      }
    },
    /***********************
     * MAP Methods 
     * Inicio
     **********************/
    selectAllPlants() {
      if(this.map.selectAllPlants){
        let tempPlantas = JSON.parse(JSON.stringify(this.catalogues.plantas))
        let tempSelect=[]
        for(let i=0;i<tempPlantas.length;i++){
          tempSelect.push(tempPlantas[i].id)
        }
        this.map.select.plants = [... tempSelect]
      }
      else{
        this.map.select.plants = []
      }
      if(this.map.switchSpiders){
        setTimeout(() => {
          this.toggleSpiders(true)
        }, 500)
      }
    },
    reviewEvents() {
      if(this.map.switchSpiders){
        setTimeout(() => {
          this.toggleSpiders(true)
        }, 500)
      }
    },
    toggleSpiders(bitUpdate) {
      if(!bitUpdate){
        this.map.spiders=[]
        this.map.select.services= JSON.parse(JSON.stringify(this.scheduling.orders.items))
        return
      }      
      let paths=[]
      let colors=['#00f','#f00','#0f0','#000','#0ff','#f0f','#777','#700','#070','#aaa','#0A0','#0AA'] 

      this.map.select.services=[]
      let tempPlanta=''
      let objPlantas={}
      let tempColor=''
      let tempPlantas = JSON.parse(JSON.stringify(this.catalogues.plantas))
      let tempItems = JSON.parse(JSON.stringify(this.scheduling.orders.items))
      let tempClusters = this.$refs.clusterer?[... this.$refs.clusterer.$clusterObject.clusters_]:[]
      let tempJson={}
      let tempClusterPlantas={}
      let tempKeys=[]
      let tempMarkers=[]
      for(let i=0;i<tempPlantas.length;i++){
        if((this.map.select.plants && this.map.select.plants.find(element => element==tempPlantas[i].id))){
          objPlantas[tempPlantas[i].codigo]= tempPlantas[i]
        }
      }

      if(this.map.switchAgrupa){
        for(let i=0; i< tempClusters.length;i++){
          tempMarkers= [...this.$refs.clusterer.$clusterObject.clusters_[i].markers_]
          tempClusterPlantas={}
          for(let x=0; x < tempMarkers.length;x++){
            tempJson= this.getFormatJSON(tempMarkers[x].title)
            tempClusterPlantas[tempJson.planta]=1
            this.map.select.services.push(JSON.parse(JSON.stringify(tempJson)))
          }
          tempKeys = Object.keys(tempClusterPlantas)
          
          for(let y=0;y<tempKeys.length;y++){
            if(objPlantas[tempKeys[y]]){
              tempColor = colors[objPlantas[tempKeys[y]].id % colors.length]
              paths.push({
                path:[
                  {lat: (objPlantas[tempKeys[y]].ubicacion.coordinates[0]), lng: (objPlantas[tempKeys[y]].ubicacion.coordinates[1]) },
                  {lat: (tempClusters[i].getCenter().lat()), lng: (tempClusters[i].getCenter().lng()) }],
                  pathOptions:{
                    strokeColor: this.map.colorSpider?tempColor:'#000',
                    strokeWeight: 4,
                    editable: false,
                    strokeOpacity: 0,
                    icons: [{
                      icon: {
                        path: 'M 0,-1 0,1',
                        strokeOpacity: 1,
                        scale: 2
                      },
                      offset: '0',
                      repeat: '10px'
                    }, {
                      icon: {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        offset: '100%',
                        fillColor: this.map.colorSpider?tempColor:'#000',
                        fillOpacity: 1,
                        strokeOpacity: 1,
                        scale: 2
                      }
                    }],               
                  }
              })
            }
          }
        }
      }
      else{
        for(let i=0; i< tempItems.length;i++){
          tempPlanta= tempItems[i].planta          
          if(objPlantas[tempPlanta]){
            tempColor = colors[objPlantas[tempPlanta].id % colors.length]
            paths.push({
              path:[
                {lat: (objPlantas[tempPlanta].ubicacion.coordinates[0]), lng: (objPlantas[tempPlanta].ubicacion.coordinates[1]) },
                {lat: (tempItems[i].latitud*1), lng: (tempItems[i].longitud*1) }],
                pathOptions:{
                  strokeColor: this.map.colorSpider?tempColor:'#000',
                  strokeWeight: 4,
                  editable: false,
                  strokeOpacity: 0,
                  icons: [{
                    icon: {
                      path: 'M 0,-1 0,1',
                      strokeOpacity: 1,
                      scale: 2
                    },
                    offset: '0',
                    repeat: '10px'
                  }, {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      offset: '100%',
                      fillColor: this.map.colorSpider?tempColor:'#000',
                      fillOpacity: 1,
                      strokeOpacity: 1,
                      scale: 2
                    }
                  }],
                }
            })
          }
        }
      }
      this.map.spiders = [... paths]
      this.map.loadingSpiders=false
    },
    toggleColorSpiders() {
      if(this.map.switchSpiders){
        this.toggleSpiders(true)
      }
    },
    changeIdle() {
      //this.center = this.$refs.gmap.$mapObject.getCenter()
      this.map.actualZoom =  this.$refs.gmap.$mapObject.getZoom()
      
      if(!this.map.loadingSpiders && this.map.switchSpiders && this.map.switchAgrupa){
      this.map.loadingSpiders=true
        setTimeout(() => {
          this.toggleSpiders(true)
        }, 600);
      }  
    },
    changeBounds() {
      if(this.map.infoWinOpen){
        this.repositionInfoWindow()
      } 
    },
    getFormatPosition(location) {
      return (location)?{lat:location.coordinates[0], lng:location.coordinates[1]}:{lat:0,lng:0}
    },    
    toggleInfoWindow(event) {
      this.map.infoWindowTitle='Agrupación'
      let markers = event.getMarkers();
      let titles = [];

      //Get all the titles
      for(var i = 0; i < markers.length; i++) {
        titles.push(this.getFormatJSON(markers[i].getTitle()));
      }      
      this.map.infoContent= titles
      this.map.infoWindowPos = event.getCenter();
      
      //check if its the same marker that was selected if yes toggle
      if (this.map.currentMidx == event.getCenter().lat()+','+event.getCenter().lng()) {
        this.map.infoWinOpen = !this.map.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.map.infoWinOpen = true;
        this.map.currentMidx = event.getCenter().lat()+','+event.getCenter().lng();
      }
    },
    toggleInfoWindowMarker(marker) {
      this.map.infoWindowTitle='' + marker.pedido+' - '+ marker.carga
      let titles = [];
      let coords= {lat:marker.latitud*1, lng:marker.longitud*1}
      titles.push(marker);
      this.map.infoContent= titles
      this.map.infoWindowPos = coords

      //check if its the same marker that was selected if yes toggle
      if (this.map.currentMidx == coords['lat']+','+coords['lng']) {
        this.map.infoWinOpen = !this.map.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.map.infoWinOpen = true;
        this.map.currentMidx = coords['lat']+','+coords['lng'];
      }
    },
    setCalculator(markers, numStyles) {
      //create an index for icon styles
      var index = 0,
      //Count the total number of markers in this cluster
        count = markers.length,
      //Set total to loop through (starts at total number)
      total = count;
      
      let volumen = 0        
      for(let i=0;i<markers.length;i++){
        volumen += markers[i].label*1//this.getFormatJSON(markers[0].title)['volumen']*1
      }

      /**
       * While we still have markers, divide by a set number and
       * increase the index. Cluster moves up to a new style.
       *
       * The bigger the index, the more markers the cluster contains,
       * so the bigger the cluster.
       */
      /* while (total !== 0) {
        //Create a new total by dividing by a set number
        total = parseInt(total / 5, 10);
        //Increase the index and move up to the next style
        index++;
      } */
      if(total <= 5){ index=1}
      else if(total <= 20){ index=2}
      else if(total <= 50){ index=3}
      else if(total < 100){ index=4}
      else { index=5}

      /**
       * Make sure we always return a valid index. E.g. If we only have
       * 5 styles, but the index is 8, this will make sure we return
       * 5. Returning an index of 8 wouldn't have a marker style.
       */
      index = Math.min(index, numStyles);

      //Tell MarkerCluster this clusters details (and how to style it)
      let styledText=''
      styledText= this.map.agrupaMini?'':"<div style='position:absolute;top:19px;width:100%;'>"+this.numberWithCommas(volumen)+"</div>"+
                  "<div style='position:absolute;left:9px;top:0px;width:100%;'>"+count+"</div>"
      return {
        text: styledText,
        index: index
      }; 
    },
    numberWithCommas(n) {
      var parts=n.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substr(0, 2) : "");
    },
    numberWithCommasND(n) {
      var parts=n.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    numberWithCommasCoords(n) {
      var parts=n.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substr(0, 6) : "");
    },
    getFormatText(obj) {
      let newStr = '';
      let filteredElements= Object.keys(obj).filter(function(value, index, arr){ 
        return value !== 'id' && value !=='ubicacion';
      });
      let x = filteredElements.map(function(item) {        
        newStr = item+': '+obj[item];
        return newStr;        
      }).join('\n');
      return x
    },
    getFormatJSON(txt) {
      let tempObj= {}
      tempObj=txt.split('\n')
      let newObj = tempObj.reduce((a,v) => ({ ...a, [v.substring(0,v.indexOf(":"))]:(v.substring(v.indexOf(":")+1,v.length)).trim()}),{})      
      return newObj
    },
    repositionInfoWindow() {
      if(this.map.switchAgrupa){
        if(this.map.agrupaMini){
          this.map.infoOptions.pixelOffset.height=-13
        }
        else{
          this.map.infoOptions.pixelOffset.height=-50
        }
      }
      else{
        if(this.map.actualZoom>13){
          this.map.infoOptions.pixelOffset.height=-26
        }else{
          this.map.infoOptions.pixelOffset.height=-13
        }
      }
    },
    changeAgrupa() {
      this.map.infoWinOpen=false
      if(this.map.switchSpiders){
        this.toggleSpiders(true)
      }
      this.repositionInfoWindow()
    },
    clickPlant(id) {
      if(this.map.select.plants && this.map.select.plants.find(element => element==id)){        
        let filtered = this.map.select.plants.filter(function(value, index, arr){
          return value !== id;
        });
        this.map.select.plants = [... filtered]
      }
      else{
        this.map.select.plants.push(id)
      }
      
      if(this.map.switchSpiders){
        setTimeout(() => {
          this.toggleSpiders(true)
        }, 500);
      }
    },
    getColor(value) {
      if (value < 4) return 'red'
      else if (value < 6) return 'orange'
      else return 'green'
    },
    updateMapStyle() {
      this.$refs.gmap.$mapObject.setOptions({ styles: this.map.mapStyles[this.map.mapSelectedStyle] })
    },
    clearSelection() {
      if (this.map.drawing.selectedShape) {
        if(this.map.drawing.selectedShape.type != google.maps.drawing.OverlayType.MARKER){
          this.map.drawing.selectedShape.setEditable(false);          
        }
        this.map.drawing.selectedShape.setDraggable(false);
        this.map.drawing.selectedShape = null;        
      }
    },
    setSelection(shape) {
      this.clearSelection();      
      this.map.drawing.selectedShape = shape;
      if(shape.type != google.maps.drawing.OverlayType.MARKER){
        shape.setEditable(true);
        this.selectColor(shape.get('fillColor') || shape.get('strokeColor'));
      }
      shape.setDraggable(true);
    },
    deleteSelectedShape() {
      if (this.map.drawing.selectedShape) {        
        let indexToFind= this.map.drawing.selectedShape.all_overlays_index
        let tempObjects= [... this.map.drawing.all_overlays]
        
        for(let i=indexToFind+1;i< tempObjects.length;i++){
          tempObjects[i].overlay.all_overlays_index= tempObjects[i].overlay.all_overlays_index - 1
        }
        tempObjects.splice(indexToFind, 1);
        this.map.drawing.selectedShape.setMap(null);
        this.map.drawing.selectedShape=null
        this.map.drawing.all_overlays= [... tempObjects]
      }
    },
    deleteAllShape() {
      for (let i = 0; i < this.map.drawing.all_overlays.length; i++) {
        this.map.drawing.all_overlays[i].overlay.setMap(null);
      }
      this.map.drawing.all_overlays = [];
    },
    selectColor(color) {
      this.map.drawing.selectedColor = color;

      // Retrieves the current options from the drawing manager and replaces the
      // stroke or fill color as appropriate.
      let polylineOptions = this.map.drawing.drawingManager.get('polylineOptions');
      polylineOptions.strokeColor = color;
      this.map.drawing.drawingManager.set('polylineOptions', polylineOptions);

      let rectangleOptions = this.map.drawing.drawingManager.get('rectangleOptions');
      rectangleOptions.fillColor = color;
      //rectangleOptions.strokeColor = color;
      this.map.drawing.drawingManager.set('rectangleOptions', rectangleOptions);

      let circleOptions = this.map.drawing.drawingManager.get('circleOptions');
      circleOptions.fillColor = color;
      //circleOptions.strokeColor = color;
      this.map.drawing.drawingManager.set('circleOptions', circleOptions);

      let polygonOptions = this.map.drawing.drawingManager.get('polygonOptions');
      polygonOptions.fillColor = color;
      //polygonOptions.strokeColor = color;
      this.map.drawing.drawingManager.set('polygonOptions', polygonOptions);
    },
    setSelectedShapeColor(color) {
      if (this.map.drawing.selectedShape) {
        if (this.map.drawing.selectedShape.type == google.maps.drawing.OverlayType.POLYLINE) {
          this.map.drawing.selectedShape.set('strokeColor', color);
        } else {
          this.map.drawing.selectedShape.set('fillColor', color);
          //this.map.drawing.selectedShape.set('strokeColor', color);
        }
      }
    },
    removeVertex(e,shape) {
      shape.removeAt(e.vertex)
    },
    getInfoDrawing(shape) {

      let tempServices = [... this.map.select.services]
      let tempPlants = [... this.catalogues.plantas]
      let coordinate= null
      let isWithinPolygon= null
      let acumServices=0
      let acumVolumen=0
      let acumPlantas=0

      if(shape.type=='polygon'){

        for(let i=0;i<tempServices.length;i++){
          coordinate = new google.maps.LatLng(tempServices[i].latitud,tempServices[i].longitud);
          isWithinPolygon = google.maps.geometry.poly.containsLocation(coordinate,shape);
          if(isWithinPolygon){
            acumServices+=1
            acumVolumen+= tempServices[i].volumen*1
          }
        }
        for(let i=0;i<tempPlants.length;i++){
          coordinate = new google.maps.LatLng(tempPlants[i].ubicacion.coordinates[0],tempPlants[i].ubicacion.coordinates[1]);
          isWithinPolygon = google.maps.geometry.poly.containsLocation(coordinate,shape);
          if(isWithinPolygon){
            acumPlantas+=1            
          }
        }
        this.map.drawing.infoDrawing={ servicios: acumServices, volumen: acumVolumen, plantas: acumPlantas, tipo: 'POLIGONO', color:shape.get('fillColor'), distancia: this.numberWithCommas(google.maps.geometry.spherical.computeArea(shape.getPath())/ (1000000))+' Km'}
      }
      if(shape.type=='circle'){
        let tempRadius = shape.getRadius()
        let tempCenter = shape.getCenter()

        for(let i=0;i<tempServices.length;i++){
          coordinate = new google.maps.LatLng(tempServices[i].latitud,tempServices[i].longitud);
          isWithinPolygon = google.maps.geometry.spherical.computeDistanceBetween(coordinate, tempCenter) <= tempRadius
          if(isWithinPolygon){
            acumServices+=1
            acumVolumen+= tempServices[i].volumen*1
          }
        }
        for(let i=0;i<tempPlants.length;i++){
          coordinate = new google.maps.LatLng(tempPlants[i].ubicacion.coordinates[0],tempPlants[i].ubicacion.coordinates[1]);
          isWithinPolygon = google.maps.geometry.spherical.computeDistanceBetween(coordinate, tempCenter) <= tempRadius
          if(isWithinPolygon){
            acumPlantas+=1
          }
        }
        this.map.drawing.infoDrawing={ servicios: acumServices, volumen: acumVolumen, plantas: acumPlantas, tipo: 'CIRCULO', color:shape.get('fillColor'), distancia: 'Radio: '+this.numberWithCommas(tempRadius/1000) + ' Km'}
      }
      if(shape.type=='rectangle'){
        let tempBounds = shape.getBounds()        
        
        for(let i=0;i<tempServices.length;i++){
          coordinate = new google.maps.LatLng(tempServices[i].latitud,tempServices[i].longitud);
          isWithinPolygon = tempBounds.contains(coordinate)
          if(isWithinPolygon){
            acumServices+=1
            acumVolumen+= tempServices[i].volumen*1
          }
        }
        for(let i=0;i<tempPlants.length;i++){
          coordinate = new google.maps.LatLng(tempPlants[i].ubicacion.coordinates[0],tempPlants[i].ubicacion.coordinates[1]);
          isWithinPolygon = tempBounds.contains(coordinate)
          if(isWithinPolygon){
            acumPlantas+=1
          }
        }
        let sw = tempBounds.getSouthWest();
        let ne = tempBounds.getNorthEast();
        let southWest = new google.maps.LatLng(sw.lat(), sw.lng());
        let northEast = new google.maps.LatLng(ne.lat(), ne.lng());
        let southEast = new google.maps.LatLng(sw.lat(), ne.lng());
        let northWest = new google.maps.LatLng(ne.lat(), sw.lng());
      
        this.map.drawing.infoDrawing={ servicios: acumServices, volumen: acumVolumen, plantas: acumPlantas, tipo: 'RECTANGULO', color:shape.get('fillColor'), distancia: this.numberWithCommas(google.maps.geometry.spherical.computeArea([northEast, northWest, southWest, southEast]) / (1000000))+' Km'}
      }
      if(shape.type=='marker'){        
        this.map.drawing.infoDrawing={ servicios: acumServices, volumen: acumVolumen, plantas: acumPlantas, tipo: 'MARCADOR', color:'#000',posicion:{lat:shape.getPosition().lat(),lng:shape.getPosition().lng()}}
      }
      if(shape.type=='polyline'){
        const polyLengthInMeters = google.maps.geometry.spherical.computeLength(shape.getPath().getArray());
        this.map.drawing.infoDrawing={ servicios: acumServices, volumen: acumVolumen, plantas: acumPlantas, tipo: 'LINEA', color:shape.get('strokeColor'),posicion:{lat:0,lng:0}, distancia: polyLengthInMeters/1000}
      }
    },
    toggleDibujos(bitUpdate){
      if(bitUpdate){        
        for (let i = 0; i < this.map.drawing.all_overlays.length; i++) {
          this.map.drawing.all_overlays[i].overlay.setMap(this.$refs.gmap.$mapObject);
        }
      } 
      else{        
        for (let i = 0; i < this.map.drawing.all_overlays.length; i++) {
          this.map.drawing.all_overlays[i].overlay.setMap(null);
        }
      }     
    },
    /***********************
     * MAP Methods
     * Fin
     **********************/
    redrawService(planta,index,event){
      const pattern = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/
      if(pattern.test(event)){
        //console.log('RedrawService',order+' y '+load +' de '+planta)
        //console.log(event)
        //console.log(this.scheduling.loadSchedule.data)
        //console.log(this.scheduling.loadSchedule.data[planta].services[index])

        let tempI= this.scheduling.loadSchedule.data[planta].services[index].plantLoad.hour + 1
        let tempK= this.scheduling.loadSchedule.data[planta].services[index].plantLoad.cell
        let tempINuevo = parseInt(event.split(':')[0])+1
        let tempKNuevo = Math.floor(parseInt(event.split(':')[1]) / 10) + 1

        let actual = {cell: index+'-'+tempI+'-'+tempK, value: this.scheduling.loadSchedule.cellInfo[index+'-'+tempI+'-'+tempK].Info, index,hour:(tempI-1),minute:(((tempK*1)-1)*10)}
        let nuevo = { cell:index+'-'+tempINuevo+'-'+tempKNuevo, hour:(tempINuevo-1), minute:(((tempKNuevo*1)-1)*10)}
                
        this.updateServicesOrder(actual,nuevo)
      }      
    },
    getServiceToolTip(service){
      let tempHtml= 'Pedido: '+ service.order +'<br/>'+
                    'Carga: '+ service.load +'<br/>'+
                    'Fecha Compromiso: '+ service.compromiso +'<br/>'+
                    'Fecha Programado: '+ service.programado +'<br/>'+
                    'Tiempo Efectivo: '+ service.effectiveMinutesDetail +'<br/>'+
                    'Volumen: '+ this.numberWithCommasND(service.volumen) +' m3<br/>'
      return tempHtml
    },
    calculateTrucks(data, bitSimulation = 0){      
      let tempKeys = Object.keys(data)      
      let currentTruck= 1
      let availableTrucks= []
      let assignedTruck= 0

      let tempMaxTrucks= {value:-1, hour:-1}
      let tempTrucksPerHour=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
      let tempTrucksArrHour
      let tempLoadsArrHour
      

      for(let i=0;i< tempKeys.length;i++){
        /*Asigna camion inicio */
        currentTruck= 1
        availableTrucks= []
        assignedTruck=0
        
        //RequiredPerHour - Trucks
        tempTrucksPerHour=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        tempMaxTrucks= {value:-1, hour:-1}
        tempTrucksArrHour= [ [],[],[],[],
                            [],[],[],[],
                            [],[],[],[],
                            [],[],[],[],
                            [],[],[],[],
                            [],[],[],[] ]
        tempLoadsArrHour= [ [],[],[],[],
                            [],[],[],[],
                            [],[],[],[],
                            [],[],[],[],
                            [],[],[],[],
                            [],[],[],[] ]
          
        let tempService ={}
        for(let k=0; k < data[tempKeys[i]].services.length;k++){
          
          if(bitSimulation == 1){
            tempService = JSON.parse(JSON.stringify(this.simulation.data[tempKeys[i]].services[k]))
          }
          else{
            tempService = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data[tempKeys[i]].services[k]))
          }
          
          if(availableTrucks.length > 0){
            if(tempService.plantLoad.hour > availableTrucks[0].hour){
              if((availableTrucks[0].hour == (tempService.plantLoad.hour-1) && availableTrucks[0].cell==6) && (tempService.plantLoad.cell<3)){  // es menor a 3 porque se necesita de espacio 2 casillas para nueva asignacion
                assignedTruck = currentTruck
                currentTruck+=1
              }
              else if((availableTrucks[0].hour == (tempService.plantLoad.hour-1) && availableTrucks[0].cell==5) && (tempService.plantLoad.cell<2)){  // es menor a 2 porque se necesita de espacio 2 casillas para nueva asignacion
                assignedTruck = currentTruck
                currentTruck+=1
              }
              else{                      
                assignedTruck = availableTrucks[0].truck
                availableTrucks.shift();                      
              }
            }
            else if (tempService.plantLoad.hour == availableTrucks[0].hour && tempService.plantLoad.cell > availableTrucks[0].cell+2){ //en availableTrucks.cell se agrega +2 porque se quiere 2 espacios extra
              assignedTruck = availableTrucks[0].truck
              availableTrucks.shift();
            }
            else{
              assignedTruck = currentTruck
              currentTruck+=1
            }
          }
          else{
            assignedTruck = currentTruck
            currentTruck+=1
          }
          /* Asigna camion fin */

          if(bitSimulation == 1){
            this.simulation.data[tempKeys[i]].services[k].truck = assignedTruck
          }
          else{
            this.scheduling.loadSchedule.data[tempKeys[i]].services[k].truck = assignedTruck
          }          
          
          if(tempService.toPlant.rDay>0) {
            availableTrucks.push({truck:assignedTruck, cell:1, hour:99, key:99.1})
          }
          else {
            availableTrucks.push({truck:assignedTruck, cell:tempService.toPlant.lastCell, hour:tempService.toPlant.lastHour, key:(tempService.toPlant.lastHour+'.'+tempService.toPlant.lastCell)*1})
          }
          availableTrucks.sort(function(a,b){
            if (!isNaN(parseFloat(a.key)) && !isNaN(parseFloat(b.key))){ //Ordena los camiones por menor tiempo
              return a.key - b.key;
              }
            else{
              if ( a.key > b.key ) return 1;
              if ( a.key < b.key ) return -1;
              return 0;
            }
          });          

          //RequiredPerHour - Trucks
          for(let j=data[tempKeys[i]].services[k].plantLoad.hour;j<= data[tempKeys[i]].services[k].toPlant.lastHour;j++){            
            if(!tempTrucksArrHour[j].find(element => element == assignedTruck)){
              tempTrucksArrHour[j].push(assignedTruck)
            }
          }
          if(bitSimulation == 1){
            tempLoadsArrHour[data[tempKeys[i]].services[k].plantLoad.hour].push(this.simulation.data[tempKeys[i]].services[k])
          }
          else{
            tempLoadsArrHour[data[tempKeys[i]].services[k].plantLoad.hour].push(this.scheduling.loadSchedule.data[tempKeys[i]].services[k])
          }

        }

        for(let g=0;g<24;g++){
          tempTrucksPerHour[g]= tempTrucksArrHour[g].length
          if(tempTrucksPerHour[g] > tempMaxTrucks.value){
            tempMaxTrucks.value= tempTrucksPerHour[g]
            tempMaxTrucks.hour = g
          }
        }
        if(bitSimulation == 1){
          this.simulation.requiredPerHour.trucks[tempKeys[i]] = JSON.parse(JSON.stringify(tempTrucksPerHour))
          this.simulation.requiredPerHour.maxTrucks[tempKeys[i]]= JSON.parse(JSON.stringify(tempMaxTrucks))
          this.simulation.requiredPerHour.trucksArrHour[tempKeys[i]]= JSON.parse(JSON.stringify(tempTrucksArrHour))
          this.simulation.requiredPerHour.loadsArrHour[tempKeys[i]]= JSON.parse(JSON.stringify(tempLoadsArrHour))
        }
        else{
          this.scheduling.loadSchedule.requiredPerHour.trucks[tempKeys[i]] = JSON.parse(JSON.stringify(tempTrucksPerHour))
          this.scheduling.loadSchedule.requiredPerHour.maxTrucks[tempKeys[i]]= JSON.parse(JSON.stringify(tempMaxTrucks))
          this.scheduling.loadSchedule.requiredPerHour.trucksArrHour[tempKeys[i]]= JSON.parse(JSON.stringify(tempTrucksArrHour))
          this.scheduling.loadSchedule.requiredPerHour.loadsArrHour[tempKeys[i]]= JSON.parse(JSON.stringify(tempLoadsArrHour))
        }

      }
      /* console.log('SIMULATION OBJECT',this.simulation) */

    },
    startDrag(evt, item) {      
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('text/html', item)
    },
    onDrop(evt, pnew) {
      const item = JSON.parse(evt.dataTransfer.getData('text/html'))
      //console.log(item)
      const list= JSON.parse(pnew)

      this.updateServicesOrder(item,list) //Actualiza las cargas por el efecto de mover el start o frecuencia

    },
    calculateStatus(servicio){
          
          let tempService= {} //Objeto tipo servicio que se envia en return
          
          tempService.service = servicio.pedido +' - '+ servicio.carga

          let hour = parseInt(servicio.fecha_programado.split(' ')[1].split(':')[0]) //Hora de la hora programada
          let minute = parseInt(servicio.fecha_programado.split(' ')[1].split(':')[1]) //Minuto de la hora programada
          let serviceSize=0 //Tamaño del servicio en grfico depente #cells y cambios de hora
          let serviceHourChange=0 // cambio de hora durante servicio
                    
          /*************************
           * Plant Load
           ************************/          
          // Si el tiempo de carga en planta no es mayor a 10min solo se considera 1 sola celda
          let tempPlantLoadTime = 10
          let tempCellPLStart = Math.floor(minute / 10) //Aqui es sin +1 porque más adelante en ciclo for se le suma
          let tempHrPLStart= hour
          let tempCells= {}

          const tempPL= {
            hour: hour,
            minute: minute,
            cell: Math.floor(minute / 10) + 1,
            color:'#FFE594',
            lastCell: 1,
            lastHour: 1,
            cells: {}
          }

          for(let g=0; g < Math.ceil(tempPlantLoadTime / 10); g++){
            if(tempCellPLStart < 6){
              tempCellPLStart+= 1
            }
            else{
              tempCellPLStart= 1
              tempHrPLStart= (tempHrPLStart < 23)? tempHrPLStart + 1: 0
            }
            tempCells[tempHrPLStart +'-'+ tempCellPLStart]=1
            tempPL.lastCell= tempCellPLStart
            tempPL.lastHour= tempHrPLStart
            serviceSize+=1
          }
          tempPL.cells= JSON.parse(JSON.stringify(tempCells))
          serviceHourChange+= (tempPL.lastHour > tempPL.hour)? tempPL.lastHour - tempPL.hour:0

          tempService.plantLoad = JSON.parse(JSON.stringify(tempPL))          

          /*************************
           * To Job Site
           ************************/
          let tempCellsTJB={}
          let tempCellPL= tempPL.lastCell
          let tempHrPL= tempPL.lastHour          
          let tempTransitTime = (Object.entries(this.scheduling.times.times).length === 0)? 30 : this.scheduling.times.times[servicio.pedido][servicio.planta] //30min como default
          const tempTJB = {
            transitTime: tempTransitTime,
            color: '#7F9DDB',
            lastCell: 1, // Inicializa con 1
            lastHour: 1, // Inicializa con 1
            hour: hour,
            cells: {},  //Inicializa con vacío
            cell: 1 // Inicializa con 1
          }        
          for(let g=0; g < Math.ceil(tempTransitTime / 10); g++){
            if(tempCellPL < 6){
              tempCellPL+= 1
            }
            else{
              tempCellPL= 1
              tempHrPL= (tempHrPL < 23)? tempHrPL + 1: 0
            }
            
            if(g==0){
              tempTJB.hour = tempHrPL
              tempTJB.cell= tempCellPL
            }
            
            tempCellsTJB[tempHrPL +'-'+ tempCellPL]=1
            tempTJB.lastCell= tempCellPL
            tempTJB.lastHour= tempHrPL
            serviceSize+=1
          }
          tempTJB.cells= JSON.parse(JSON.stringify(tempCellsTJB))
          serviceHourChange+= (tempTJB.lastHour > tempTJB.hour)? tempTJB.lastHour - tempTJB.hour:0
          serviceHourChange+= (tempTJB.hour >= tempPL.lastHour)? tempTJB.hour - tempPL.lastHour:
                              (tempTJB.lastHour > tempPL.lastHour)? tempTJB.lastHour - tempPL.lastHour:0

          tempService.toJobSite = JSON.parse(JSON.stringify(tempTJB))

          /*************************
           * On Job Site
           ************************/
          let tempCellsOJB={}
          let tempCellsPumpStart={}
          let tempCellsPumpEnd={}
          let beginPumpCell=1
          let beginPumpHour=0
          let lastPumpCell=1
          let lastPumpHour=0
                    
          //Obtiene la celda inmediatamente después de la última celda del tjb. Que es la llegada con cliente
          let tempCell = (tempTJB.lastCell < 6)? tempTJB.lastCell + 1: 1
          let tempHr = (tempTJB.lastCell < tempCell)? tempTJB.lastHour: tempTJB.lastHour+1
          let tempMin = (tempCell - 1) * 10

          //Obtiene el highlight (Se resalta la hora compromiso con un punto en la celda)
          //Revisa que este el compromiso y programada mismo dia          
          let diaCompromiso= parseInt(servicio.fecha_compromiso.split(' ')[0].split('-')[2])
          let diaProgramada= parseInt(servicio.fecha_programado.split(' ')[0].split('-')[2])
          let tempCellHL= 1
          let tempHrHL= 0
          let tempMinHL= 0
          let tempCellHLEnd= 1
          let tempHrHLEnd= 0
          let tempMinHLEnd= 0
          if(diaCompromiso == diaProgramada) {
            tempCellHL= Math.floor(parseInt(servicio.fecha_compromiso.split(' ')[1].split(':')[1])/10)+1
            tempHrHL= parseInt(servicio.fecha_compromiso.split(' ')[1].split(':')[0])
            tempMinHL= parseInt(servicio.fecha_compromiso.split(' ')[1].split(':')[1])
            tempCellHLEnd = tempCellHL
            tempHrHLEnd = tempHrHL
            tempMinHLEnd = tempMinHL
          }

          const tempOJB = {
            hour: tempHr,
            minute: tempMin,
            cell: tempCell,
            color: '#FEC58F',
            lastCell: 1,
            lastHour: 1,
            highlight: { hour: tempHrHL,
                         minute: tempMinHL,
                         cell: tempCellHL,
                         time: servicio.fecha_compromiso
                       },
            cells: {},
            pump:{
              cellsPumpStart: {},
              cellsPumpEnd: {},
              beginPumpCell:1,
              beginPumpHour:0,
              lastPumpCell:1,
              lastPumpHour:0
            }
          }
          tempCellsOJB[tempHr+'-'+tempCell]=1
          serviceSize+=1

          for(let g=0;g < 2;g++){ //30 minutos en sitio
            if(tempCell < 6){
              tempCell+= 1
            }
            else{
              tempCell= 1
              tempHr=(tempHr < 23)? tempHr + 1: 0
            }
            if(tempHr == 24){ tempHr=0; }
            tempCellsOJB[tempHr + '-' + tempCell]=1
            tempOJB.lastCell= tempCell
            tempOJB.lastHour= tempHr
            serviceSize+=1
          }
          tempOJB.cells= JSON.parse(JSON.stringify(tempCellsOJB))
          serviceHourChange+= (tempOJB.lastHour > tempOJB.hour)? tempOJB.lastHour - tempOJB.hour:0
          serviceHourChange+= (tempOJB.hour >= tempTJB.lastHour)? tempOJB.hour - tempTJB.lastHour:
                              (tempOJB.lastHour > tempTJB.lastHour)? tempOJB.lastHour - tempTJB.hour:0
          
          //Encuentra el PumpStart segun el compromiso
          for(let g=0;g < 3;g++){ //30 minutos antes del compromiso
            
            //Pump Start
            if(tempCellHL > 1){
              tempCellHL-= 1
            }
            else{
              tempCellHL= 6
              tempHrHL=(tempHrHL>0)? tempHrHL - 1: 23
            }
            if(tempHrHL==0){ tempHrHL=23; }
            tempCellsPumpStart[tempHrHL+'-'+tempCellHL]=1
            beginPumpCell=tempCellHL
            beginPumpHour=tempHrHL
                        
            if(tempCellHLEnd<6){
              tempCellHLEnd+= 1
            }
            else{
              tempCellHLEnd= 1
              tempHrHLEnd=(tempHrHLEnd < 23)? tempHrHLEnd + 1:0
            }
            if(tempHrHLEnd== 24){ tempHrHLEnd=0; }
            tempCellsPumpEnd[tempHrHLEnd + '-' + tempCellHLEnd]= 1
            lastPumpCell= tempCellHLEnd
            lastPumpHour= tempHrHLEnd
            //Pump End
          }
          tempOJB.pump.cellsPumpStart= JSON.parse(JSON.stringify(tempCellsPumpStart))
          tempOJB.pump.cellsPumpEnd= JSON.parse(JSON.stringify(tempCellsPumpEnd))
          tempOJB.pump.beginPumpCell= JSON.parse(JSON.stringify(beginPumpCell))
          tempOJB.pump.beginPumpHour= JSON.parse(JSON.stringify(beginPumpHour))
          tempOJB.pump.lastPumpCell= JSON.parse(JSON.stringify(lastPumpCell))
          tempOJB.pump.lastPumpHour= JSON.parse(JSON.stringify(lastPumpHour))

          tempService.onJobSite = JSON.parse(JSON.stringify(tempOJB))
          
          /*************************
           * To Plant
           ************************/
          let tempCellsTPL={}
          let tempCellTPL= tempOJB.lastCell
          let tempHrTPL= tempOJB.lastHour
          
          let tempCellTPLStart = (tempOJB.lastCell < 6)? tempOJB.lastCell + 1: 1
          let tempHrTPLStart = (tempOJB.lastCell < tempCellTPLStart)? tempOJB.lastHour: tempOJB.lastHour+1
          let tempMinTPLStart = (tempCellTPLStart - 1) * 10

          const tempTPL={
            transitTime: tempTransitTime,
            color: '#CBE3AD',
            lastCell: 1,
            lastHour: 1,
            cells: {},
            rDay:0,
            hour: tempHrTPLStart,
            minute: tempMinTPLStart,
            cell:1
          }
          for(let g=0;g < Math.ceil(tempTransitTime / 10);g++){
            if(tempCellTPL < 6){
              tempCellTPL+= 1
            }
            else{
              tempCellTPL=1
              tempHrTPL=(tempHrTPL < 23)? tempHrTPL+1:0
            }
            
            if(g==0){ tempTPL.cell= tempCellTPL}
            
            tempCellsTPL[tempHrTPL + '-' + tempCellTPL]=1
            tempTPL.lastCell= tempCellTPL
            tempTPL.lastHour= tempHrTPL
            tempTPL.rDay= (tempTPL.lastHour >= tempPL.lastHour)? 0:1 //0 mismo día, 1 siguiente día
            serviceSize+=1
          }
          tempTPL.cells= JSON.parse(JSON.stringify(tempCellsTPL))
          serviceHourChange+= (tempTPL.lastHour > tempTPL.hour)? (tempTPL.lastHour - tempTPL.hour):0
          serviceHourChange+= (tempTPL.hour >= tempOJB.lastHour)? tempTPL.hour - tempOJB.lastHour:
                              (tempTPL.lastHour > tempOJB.lastHour)? tempTPL.lastHour - tempOJB.lastHour:0

          tempService.toPlant = JSON.parse(JSON.stringify(tempTPL))
          
          /*************************
           * Actualiza información general
           ************************/
          tempService.volumen = servicio.volumen
          tempService.planta = servicio.planta
          tempService.order = servicio.pedido
          tempService.load = servicio.carga
          tempService.latitud = servicio.latitud
          tempService.longitud = servicio.longitud          
          tempService.compromisoEnMin= (tempOJB.highlight.hour*60) + tempOJB.highlight.minute
          tempService.truck= 'TBD'
          tempService.compromiso= servicio.fecha_compromiso
          tempService.programado= servicio.fecha_programado
          tempService.programadoEnMin= parseInt(servicio.fecha_programado.split(' ')[1].split(':')[0])*60 + parseInt(servicio.fecha_programado.split(' ')[1].split(':')[1])
          tempService.serviceSize = serviceSize
          tempService.serviceHourChange = serviceHourChange
          tempService.cliente = servicio.cliente
          tempService.proyecto = servicio.proyecto

          let tempEffMin= ((Object.keys(tempPL.cells).length + Object.keys(tempTJB.cells).length + Object.keys(tempOJB.cells).length + Object.keys(tempTPL.cells).length) * 10) //Tiempo en min en el que el camion está en el servicio (PL+TJB+OJB+TPL)
          let tempEffMinDet=  { 
                                PL:  (Object.keys(tempPL.cells).length * 10),
                                TJB: (Object.keys(tempTJB.cells).length * 10),
                                OJB: (Object.keys(tempOJB.cells).length * 10),
                                TPL: (Object.keys(tempTPL.cells).length * 10)
                              }
          tempService.effectiveMinutes= tempEffMin,
          tempService.effectiveMinutesDetail=
                                          'PL:'+ tempEffMinDet.PL +', '+
                                          'TJB:'+ tempEffMinDet.TJB +', '+
                                          'OJB:'+ tempEffMinDet.OJB +', '+
                                          'TPL:'+ tempEffMinDet.TPL
          /*** LoadSchedule end ***/
        //}
      return tempService
    },
    getTime(hour,minutes){
      let tempHour = hour<10?'0'+hour:hour
      let tempMin = minutes<10?'0'+minutes:minutes
      return tempHour+':'+tempMin
    },
    updateService(item,list){
      //item es el elemento actual
      //list es el nuevo elemento a donde se va a mover el servicio
      let tempPlant= this.scheduling.loadSchedule.plantSelected      
      let tempService = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data[tempPlant].services[item.index]))
      let newTime= tempService.programado.split(' ')[0]+' '+ this.getTime(list.hour,list.minute)
      //console.log(newTime)
      
      let tempNewService = {
            planta: tempService.planta,
            pedido: parseInt(tempService.order),
            carga: tempService.load,
            fecha_compromiso: tempService.compromiso,
            fecha_programado: newTime,
            volumen: parseFloat(tempService.volumen),
            latitud: parseFloat(tempService.latitud),
            longitud: parseFloat(tempService.longitud),
            cliente: tempService.cliente
          }
            
      let tempCell = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.cellInfo[item.cell]))
      let tempCellTooltips= JSON.parse(JSON.stringify(this.scheduling.loadSchedule.cellTooltips[item.cell]))
            
      delete this.scheduling.loadSchedule.cellInfo[item.cell]
      delete this.scheduling.loadSchedule.cellTooltips[item.cell]

      if(this.scheduling.loadSchedule.cellInfo[list.cell]){
        this.scheduling.loadSchedule.cellInfo[list.cell].Color = tempCell.Color
        this.scheduling.loadSchedule.cellInfo[list.cell].Info = item.value
      }
      else{
        this.scheduling.loadSchedule.cellInfo[list.cell] = JSON.parse(JSON.stringify(tempCell))
        this.scheduling.loadSchedule.cellTooltips[list.cell] = JSON.parse(JSON.stringify(tempCellTooltips))
      }

      this.scheduling.loadSchedule.data[tempPlant].services[item.index] = this.calculateStatus(tempNewService)
      //console.log(this.scheduling.loadSchedule.data[tempPlant].services[item.index])

      //console.log(list.cell)
      this.scheduling.loadSchedule.dynamic+=1
    },
    updateServicesOrder(item,list){

      let tempPlant= this.scheduling.loadSchedule.plantSelected      
      let tempService = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data[tempPlant].services[item.index]))
      let temp = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data[tempPlant].services))
      this.scheduling.loadSchedule.indexServicesOrder=[]
      this.scheduling.loadSchedule.serviceSelected = tempService.service

      let tempIndexFrecuency=0

      for(let i=0;i<temp.length;i++){
        if(temp[i].order=== tempService.order){
          this.scheduling.loadSchedule.indexServicesOrder.push(i)
          if(i==item.index){
            tempIndexFrecuency= this.scheduling.loadSchedule.indexServicesOrder.length
          }
        }        
      }
      
      //console.log('Indexes',this.scheduling.loadSchedule.indexServicesOrder)
      
      this.updateService(item, list) //Actualiza la carga start que estás moviendo

      let filtered = []
      if(tempService.load!=='L001'){ //cambia la frecuencia del pedido
        //console.log('Aqui quiere mover frecuencia')        
      }
      else{
      
      filtered = this.scheduling.loadSchedule.indexServicesOrder.filter((value, index, arr) =>{ return value !== item.index; });      
      let itemFilter= {}
      let listFilter= {}
      let serviceFrecuency = 0
      let newServiceHour=0
      let newServiceMinute=0
      let tempMin=0
        for(let i=0;i < filtered.length;i++) {

          if(tempService.load!=='L001' && filtered[i]==item.index){
            continue;
          }

          tempService = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data[tempPlant].services[filtered[i]]))
          itemFilter = { cell: filtered[i]+'-'+(tempService.plantLoad.hour+1)+'-'+tempService.plantLoad.cell, value: 'TBD', index: filtered[i], hour: tempService.plantLoad.hour, minute: tempService.plantLoad.minute }
          serviceFrecuency = this.getFrecuency(tempService.order)*(i+1)
          newServiceHour = list.hour
          newServiceMinute= list.minute
          
          if( i== 0){ 
            if(!this.scheduling.loadSchedule.changeLog.start[tempService.order]){
              this.scheduling.loadSchedule.changeLog.start[tempService.order]=[]
            }
            this.scheduling.loadSchedule.changeLog.start[tempService.order].push({fromHour:item.hour, fromMinute:item.minute, toHour:list.hour, toMinute:list.minute, frecuency:serviceFrecuency})
          }
          
          while(serviceFrecuency > 0){
            if(serviceFrecuency >= 60){
              newServiceHour= newServiceHour > 22? 0: newServiceHour+1
              serviceFrecuency = serviceFrecuency - 60
            }
            else{
              tempMin = newServiceMinute + serviceFrecuency
              newServiceHour=  tempMin > 59? newServiceHour > 22? 0: newServiceHour+1 : newServiceHour
              newServiceMinute= tempMin > 59? 0 : (newServiceMinute + serviceFrecuency)
              serviceFrecuency=  tempMin > 59? (tempMin - 60) : 0
            }
          }
          //console.log('NEW.saliendo',newServiceHour+':'+newServiceMinute)
          newServiceMinute = ((Math.floor(newServiceMinute / 10)+1)*10) - 10
          //console.log('NEW.after',newServiceHour+':'+newServiceMinute)
          listFilter = { cell: filtered[i]+'-'+ (newServiceHour + 1) +'-'+((newServiceMinute / 10)+1), hour:newServiceHour, minute: newServiceMinute }
          this.updateService(itemFilter, listFilter)
        }
      }
      
    },
    getStatusTimeRange(indice, estatus){
      let tempService={}
      let startTime='No time'
      let duration=0

      if(estatus==='ARR'){
        tempService = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data[this.scheduling.loadSchedule.plantSelected].services[indice].toPlant))
        startTime = this.getTime((tempService.lastCell<6)?tempService.lastHour:(tempService.lastHour<23)?tempService.lastHour+1:0, (tempService.lastCell<6)?((tempService.lastCell)*10):0)
        return startTime
      }

      if(estatus==='PL'){
        tempService = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data[this.scheduling.loadSchedule.plantSelected].services[indice].plantLoad))
      }
      else if(estatus==='TJB'){
        tempService = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data[this.scheduling.loadSchedule.plantSelected].services[indice].toJobSite))        
      }
      else if(estatus==='OJB'){
        tempService = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data[this.scheduling.loadSchedule.plantSelected].services[indice].onJobSite))
      }
      else if(estatus==='TPL'){
        tempService = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data[this.scheduling.loadSchedule.plantSelected].services[indice].toPlant))
      }

      startTime = this.getTime(tempService.hour, ((tempService.cell-1)*10))
      duration = Object.keys(tempService.cells).length*10
      return startTime +' - '+ duration+'min.'
    },
    getInfoService(indice){
      let tempService={}
      tempService = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data[this.scheduling.loadSchedule.plantSelected].services[indice]))

      return tempService.service
    },
    getColorCommitment(indice){
      let tempService={}
      tempService = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.data[this.scheduling.loadSchedule.plantSelected].services[indice]))

      return Math.abs(tempService.compromisoEnMin - ((tempService.onJobSite.hour*60)+tempService.onJobSite.minute))>=10?'#f00':'#0f0'
    },
    getClientType(cliente){
      let count = Object.keys(this.scheduling.orders.clients[cliente]).length
      return count < 3 ? 'C': count >= 5 ? 'A':'B'
    },
    getFrecuency(order){
      let count = Object.keys(this.scheduling.orders.orders[order]).length
      let temp = 0
      let tempOrderFrecuency = []
      if(this.scheduling.loadSchedule.changeLog.frecuency[order] && this.scheduling.loadSchedule.changeLog.frecuency[order].length>0){
        tempOrderFrecuency = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.changeLog.frecuency[order]))
        temp = tempOrderFrecuency[tempOrderFrecuency.length-1]
      }
      else if(count > 1){
        const dateA = moment(this.scheduling.orders.orders[order][0].fecha_compromiso,'YYYY-MM-DD HH:mm');
        const dateB = moment(this.scheduling.orders.orders[order][1].fecha_compromiso,'YYYY-MM-DD HH:mm');
        temp = Math.abs(dateA.diff(dateB,'minutes'))
      }

      return temp
    },
    toggleSelectService(service){
      if(service===this.scheduling.loadSchedule.serviceSelected){
        this.scheduling.loadSchedule.serviceSelected=''
      }
      else{
        this.scheduling.loadSchedule.serviceSelected=service
      }      
    },
    serviceStyle(service,transparent){
      if (this.scheduling.loadSchedule.serviceSelected==service)
        return 'color:#000;backgroundColor:rgba(196,129,47,0.8);'
      else if (this.scheduling.loadSchedule.serviceSelected && (this.scheduling.loadSchedule.serviceSelected.split(' ')[0]==service.split(' ')[0]))
        return 'color:#000;backgroundColor:rgba(255,216,0,0.8);'
      else return transparent?'color:#fff;backgroundColor:rgba(0,0,0,0.3);':'color:#fff;backgroundColor:#0D47A1;'
    },
    updateServiceFrecuency(orden,valor,planta,index){
      if(!this.scheduling.loadSchedule.changeLog.frecuency[orden]){
        this.scheduling.loadSchedule.changeLog.frecuency[orden]=[]
      }
      this.scheduling.loadSchedule.changeLog.frecuency[orden].push(Math.floor(valor/10)*10)

      let tempHour= this.scheduling.loadSchedule.data[planta].services[index].plantLoad.hour
      let tempMinute= this.scheduling.loadSchedule.data[planta].services[index].plantLoad.minute
      //console.log('redraw',planta+","+index+","+tempHour+':'+tempMinute)
      //console.log(this.scheduling.loadSchedule.changeLog)
      this.redrawService(planta,index,tempHour+':'+tempMinute)

    },
    sortAndRecalculateServices(tempLoadScheduleData, bitSimulation = 0){

      //ordena los servicios del loadSchedule por fechaProgramado
        let sortedServices
        let tempPlantas = JSON.parse(JSON.stringify(this.catalogues.plantas))
        let tempLoadsPerHour=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        let tempMaxLoads= {value:-1, hour:-1}
        
        for(let i=0;i< tempPlantas.length;i++){
          sortedServices = JSON.parse(JSON.stringify(tempLoadScheduleData[tempPlantas[i].codigo].services))          
          sortedServices.sort(function(a,b){
            if (!isNaN(parseFloat(a.programadoEnMin)) && !isNaN(parseFloat(b.programadoEnMin))){ //Ordena los servicios en la hora 
              return a.programadoEnMin - b.programadoEnMin;
            }
            else{
              if ( a.programadoEnMin > b.programadoEnMin ) return 1;
              if ( a.programadoEnMin < b.programadoEnMin ) return -1;
              return 0;
            }
          });
          tempLoadScheduleData[tempPlantas[i].codigo].services = JSON.parse(JSON.stringify(sortedServices))

          //RequiredPerHour - Loads
          tempLoadsPerHour=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          tempMaxLoads= {value:-1, hour:-1}

          
          for(let j=0;j<sortedServices.length;j++){
            tempLoadsPerHour[sortedServices[j].plantLoad.hour]+=1
            if(tempLoadsPerHour[sortedServices[j].plantLoad.hour] > tempMaxLoads.value){
              tempMaxLoads.value= tempLoadsPerHour[sortedServices[j].plantLoad.hour]
              tempMaxLoads.hour = sortedServices[j].plantLoad.hour
            }
          }

          if(bitSimulation == 1){
            this.simulation.requiredPerHour.loads[tempPlantas[i].codigo] = JSON.parse(JSON.stringify(tempLoadsPerHour))
            this.simulation.requiredPerHour.maxLoads[tempPlantas[i].codigo]= JSON.parse(JSON.stringify(tempMaxLoads))
          }
          else{
            this.scheduling.loadSchedule.requiredPerHour.loads[tempPlantas[i].codigo] = JSON.parse(JSON.stringify(tempLoadsPerHour))
            this.scheduling.loadSchedule.requiredPerHour.maxLoads[tempPlantas[i].codigo]= JSON.parse(JSON.stringify(tempMaxLoads))
          }

        }
        
        if(bitSimulation == 1){
          this.simulation.data = JSON.parse(JSON.stringify(tempLoadScheduleData))
        }
        else{
        this.scheduling.loadSchedule.data = JSON.parse(JSON.stringify(tempLoadScheduleData))
        }
        
        this.calculateTrucks(tempLoadScheduleData, bitSimulation)
    },
    formatDigit(digit){
      return (digit-1) < 10?'0'+(digit-1):digit-1
    },
    getTruckScheduleCellInfo(index,i){
      let tempMaxTrucksIndex = this.scheduling.loadSchedule.requiredPerHour.maxTrucks[this.scheduling.truckSchedule.plantSelected].value -1
      let tempTrucks = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.requiredPerHour.trucksArrHour[this.scheduling.truckSchedule.plantSelected]))
      if(tempTrucks[i-1][tempMaxTrucksIndex - index]){
        return tempTrucks[i-1][tempMaxTrucksIndex - index]
      }      
      return '&nbsp;'
    },
    getLoadScheduleCellInfo(index,i){
      let tempMaxLoadsIndex = this.scheduling.loadSchedule.requiredPerHour.maxLoads[this.scheduling.truckSchedule.plantSelected].value -1
      let tempLoads = JSON.parse(JSON.stringify(this.scheduling.loadSchedule.requiredPerHour.loadsArrHour[this.scheduling.truckSchedule.plantSelected]))
      if(tempLoads[i-1][tempMaxLoadsIndex - index]){        
        return [tempLoads[i-1][tempMaxLoadsIndex - index]]
      }      
      return [{volumen:-1, service:''}]
    },
    getLoadScheduleCellStyle(service){
              
      if(this.scheduling.loadSchedule.serviceSelected==''){
        if(service!==''){
          return 'color:#fff;backgroundColor:rgba(0,0,0,0.5);'
        }
        else{
          return 'backgroundColor:#ccc;'
        }
      }
      else if(service == this.scheduling.loadSchedule.serviceSelected){
        return 'color:#000;backgroundColor:rgba(196,129,47,0.8);'
      }
      else if((service.split('-')[0]).trim() == (this.scheduling.loadSchedule.serviceSelected.split('-')[0]).trim()){
        return 'color:#000;backgroundColor:rgba(255,216,0,0.8);'
      }
      else if(service!==''){
        return 'color:#fff;backgroundColor:rgba(0,0,0,0.5);'
      }
      else{
        return 'backgroundColor:#ccc;'
      }
    },
    getFulFillment(plant, hour, type='plant'){
      let percentage = 0
      let color = '#eee'
      if(type==='plant'){
        if(this.simulation.cumplimiento[plant]){
          percentage = Math.round(this.simulation.cumplimiento[plant].hora[hour] * 10) / 10
          if(percentage > 79){ color="#4CAF50" }
          if(percentage >= 60 && percentage < 80){ color="#F1DE81;" }
          if(percentage < 60){ color="#AD4C5C;" }
        }
      }
      else if(type==='totalplant'){
        if(this.simulation.cumplimiento[plant]){
          percentage = Math.round(this.simulation.cumplimiento[plant].dia * 10) / 10          
        }
      }
      else if(type==='totalhour'){
        if(this.simulation.cumplimiento["__PLAZA__"]){
          percentage = Math.round(this.simulation.cumplimiento["__PLAZA__"].hora[hour] * 10) / 10          
        }
      }
      else if(type==='total'){
        if(this.simulation.cumplimiento["__PLAZA__"]){
          percentage = Math.round(this.simulation.cumplimiento["__PLAZA__"].dia * 10) / 10          
        }
      }

      if(type!=='plant'){
        //color = percentage<0?'#00f':Math.round(percentage/16.7)>7?'#f00':this.simulation.heatMapColors[Math.round(percentage/16.7)]
        if(percentage > 79){ color="#4CAF50" }
        if(percentage >= 60 && percentage < 80){ color="#F1DE81;" }
        if(percentage < 60 && percentage>0){ color="#AD4C5C;" }

        if(type==='totalplant'){
          color = this.scheduling.loadSchedule.requiredPerHour.maxLoads[plant]?this.scheduling.loadSchedule.requiredPerHour.maxLoads[plant].value<1?'#eee':color:'#eee'
        }
      }
      
      return [{value:percentage, color}]
    },
    getLoadsHour(plant,hour){
      let loads = 0
      let color = '#000'
      if(this.scheduling.loadSchedule.requiredPerHour.loads[plant])
      {
        loads =  this.scheduling.loadSchedule.requiredPerHour.loads[plant][hour]
        color = '#fff'
      }
      return [{value: loads, color}]
    }
  }
}
</script>

<style scoped>
  .text-normal {
    text-transform: none !important;
  }
  .shadowLabel {    
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;    
  }
  #super {
    vertical-align:super;
    font-size: x-small;
  }
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .map-control {
    margin-top: 5px;    
  }
  table {
    /*font-family: "Fraunces", serif;*/
    font-size: 0.75rem;
    letter-spacing: 0.03333333em;
    font-weight: 400;
    font: "Roboto";

    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    border: 0px solid black;
  }
  table td,
  table th {
    border: 1px solid #777;
    border-left: 0;
    padding: 0;
  }
  table thead th {
    padding: 3px;
    position: sticky;
    top: 0;
    z-index: 4;
    width: 25vw;
    background: white;
  }
  table td {
    background: #fff;
    padding: 1px 1px;
    text-align: center;
  }

  table tbody th {
    font-weight: 100;
    /*font-style: italic;*/ 
    text-align: left;
    position: relative;
  }
  table thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 5;
  }
  table tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 4;
  }
  caption {
    text-align: left;
    padding: 0.25rem;
    position: sticky;
    left: 0;
  }
  [role="region"][aria-labelledby][tabindex] {
    width: 100%;
    max-height: 100%;
    overflow: auto;
  }
  [role="region"][aria-labelledby][tabindex]:focus {
    /*box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);*/
    outline: 0;
  }  
  .resize {
    top: 0px;
    width: 100px;
    height: 19px;
    float: left;
    border: 0; /* black thin solid */
    border-radius: 0px;
    text-align: left;
    border-right-color: black;
    position: absolute;
    overflow: hidden;
    resize: none;
    z-index: 1;
    background-color: rgba(196,129,47,0.8);
    color: #fff;
    margin-top:3px;        
  }
  td.drop {
    position: relative;
    width: 60px;
  }
  .inputCap{
    text-align:center;  
    width:28px;  
    border:0;
  }
</style>
