import { EventBus } from "../../utils/eventBus.js";
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  watch: {
    chartData: function (val) {
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted () {
    this.options.onHover = function (e, tooltipItems) {
      let activeElement = this.getElementAtEvent(e)
      if (activeElement.length) {
        const coors = {
          x: activeElement[0]._index,
          y: activeElement[0]._datasetIndex
        }
        EventBus.$emit("sendIndex", coors);
      }
    };
    this.options.onClick = function (e, tooltipItems) {
      let activeElement = this.getElementAtEvent(e)
      if (activeElement.length) {
        const coors = {
          x: activeElement[0]._index,
          y: activeElement[0]._datasetIndex
        }
        EventBus.$emit("setOrder", coors);
      }
    };
    this.renderChart(this.chartData, this.options)
  }
}
